import { useState } from 'react';
import { differenceInDays } from 'date-fns';

import { CTAButton1 } from '../../../../_shared/buttons';

import { calculateDate, renderDateTitle } from '../utils';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    filterTypeContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 20px',
        gap: '20px'
    },
    typeItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        color: '#1F2152',
        fontWeight: '600',
        fontSize: '20px'
    },
    type: {},
    typeTitle: {},
    dateRange: {
        fontSize: '14px',
        fontWeight: '300',
        letterSpacing: '0.39px'
    },
    selector: {},
    selectorBtn: {
        fontSize: '24px',
        color: '#A6A6BF',
        padding: '0',
        minWidth: 'auto',
        borderRadius: '50%'
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0'
    },
    filterBtn: {
        fontSize: '16px',
        width: '300px',
        height: '50px'
    },
    datePickerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        padding: '0 20px',
        color: '#1F2152'
    }
}));

const formatDateForFilter = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
};

const Filter = ({
    filterByType,
    filterDate,
    filterType,
    handleModalClick,
    historyFilters,
    setDateButtonActive,
    setFilterEndDate,
    setFilterDate,
    setFilterType,
    setTypeButtonActive
}) => {
    const classes = useStyles();

    const defaultFilterType = 'action_step';

    const [dateEnd, setDateEnd] = useState(new Date());
    const [dateStart, setDateStart] = useState(null);
    const [filterByDate, setFilterByDate] = useState(
        filterDate ? renderDateTitle(filterDate) : 'Last Month'
    );
    const [filterItemType, setFilterItemType] = useState(
        filterType ? filterType : 'action_step'
    );

    const dates = [
        { title: 'Last Week', value: 7 },
        { title: 'Last Month', value: 30 },
        { title: 'Last 6 Months', value: 180 },
        {
            title: 'Custom Range',
            value: Math.abs(differenceInDays(dateStart, dateEnd))
        }
    ];

    const datesToFilterType = {
        'Last Week': 7,
        'Last Month': 30,
        'Last 6 Months': 180,
        'Custom Range': Math.abs(differenceInDays(dateStart, dateEnd))
    };

    const getItemTypes = () =>
        filterByType === 'date' ? dates : historyFilters;

    const handleDateEndChange = (newValue) => {
        const difference = differenceInDays(newValue, dateStart);

        if (difference < 0) {
            setDateStart(newValue);
            setDateEnd(null);
        } else {
            setDateEnd(newValue);
        }
    };

    const handleDateStartChange = (newValue) => {
        const difference = differenceInDays(dateEnd, newValue);

        if (difference <= 0) {
            setDateStart(newValue);
            setDateEnd(null);
        } else {
            setDateStart(newValue);
        }
    };

    const handleTypeSelect = (type) => {
        if (filterByType === 'date') {
            if (filterByDate === type.title) {
                setFilterByDate(null);
            } else {
                setFilterByDate(type.title);
            }
        } else {
            if (filterItemType === type.description) {
                setFilterItemType(null);
            } else {
                setFilterItemType(type.description);
            }
        }
    };

    const handleFilterSelect = () => {
        const selectedFilterType =
            filterByType === 'date'
                ? datesToFilterType[filterByDate]
                : historyFilters.find(
                      (item) => item.description === filterItemType
                  )?.description || defaultFilterType;

        if (filterByDate === 'Custom Range') {
            setFilterEndDate(formatDateForFilter(dateEnd));
            setFilterDate(formatDateForFilter(dateStart));
        } else if (!filterByDate) {
            setFilterDate(null);
            setDateButtonActive(false);
        } else if (!filterItemType) {
            setFilterType(null);
            setTypeButtonActive(false);
        } else if (filterByType === 'date') {
            setFilterEndDate(calculateDate(0));
            setFilterDate(calculateDate(selectedFilterType));
        } else {
            setFilterType(selectedFilterType);
        }

        handleModalClick();
    };

    const renderFilterTypeItem = (
        { description, friendly_name, title, value },
        index
    ) => (
        <div className={classes.typeItem} key={index}>
            <div className={classes.type}>
                <div className={classes.typeTitle}>
                    {friendly_name || title}
                </div>
                {filterByType === 'date' && (
                    <div className={classes.dateRange}>
                        {title !== 'Custom Range'
                            ? calculateDate(value, true)
                            : 'Select Date Range'}
                    </div>
                )}
            </div>
            <Button
                className={classes.selectorBtn}
                onClick={() =>
                    handleTypeSelect({
                        description,
                        friendly_name,
                        title
                    })
                }
            >
                {filterItemType === description || filterByDate === title ? (
                    <i
                        className="fas fa-check-circle"
                        style={{ color: '#7378E8' }}
                    />
                ) : (
                    <i className="fal fa-circle" />
                )}
            </Button>
        </div>
    );

    return (
        <div className={classes.filterTypeContainer}>
            {getItemTypes().map(renderFilterTypeItem)}
            {filterByDate === 'Custom Range' && (
                <div className={classes.datePickerContainer}>
                    <DatePicker
                        label="Start Date"
                        value={dateStart}
                        onChange={(newValue) => handleDateStartChange(newValue)}
                        disableFuture
                    />
                    to
                    <DatePicker
                        label="End Date"
                        disabled={!dateStart}
                        value={dateEnd}
                        onChange={(newValue) => handleDateEndChange(newValue)}
                        disableFuture
                    />
                </div>
            )}
            <div className={classes.btnContainer}>
                <CTAButton1
                    className={classes.filterBtn}
                    onClick={handleFilterSelect}
                >
                    Filter
                </CTAButton1>
            </div>
        </div>
    );
};

export default Filter;