import { DebitCardOptions } from 'types';

import Logo from 'assets/logo-sm.png';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    debitBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px 10px 10px',
        '& img': {
            width: '50px',
            height: '50px'
        }
    },
    debitCard: {
        width: '311px',
        height: '180px',
        borderRadius: '16px'
    },
    debitNumber: {
        width: '68px',
        height: '28px',
        color: '#FFFFFF',
        background: 'transparent',
        border: 'none',
        fontFamily: 'Manrope',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '27px',
        textAlign: 'right'
    },
    debitTitle: {
        alignSelf: 'center',
        color: '#FFFFFF',
        fontSize: '20px',
        fontWeight: '700',
        padding: '0 8px'
    },
    numberContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '56px 15px 0 0'
    },
    icon: {
        color: '#FFFFFF !important'
    }
}));

type DebitViewProps = {
    debitCardOptions: DebitCardOptions[];
    debitType: null | string;
    nums: string;
    setDebitType: (type: null | string) => void;
};

export default function DebitView({
    debitCardOptions,
    debitType,
    nums,
    setDebitType
}: DebitViewProps): JSX.Element {
    const classes = useStyles();

    const placeHolder = [`_ `, `_ `, `_ `, `_`];

    const selectedOption =
        debitCardOptions && debitType
            ? debitCardOptions.find(
                  (option: DebitCardOptions) => option.type === debitType
              )
            : {
                  friendly_name: 'PEX',
                  type: 'pex',
                  card_asset:
                      'https://givesafe-dev.s3.amazonaws.com/public/images/card-example-pex.png',
                  logo_asset:
                      'https://givesafe-dev.s3.amazonaws.com/public/images/logo-pex.png',
                  asset_color: '36A8DD'
              };

    const handleChange = (event: SelectChangeEvent) => {
        setDebitType(event.target.value);
    };

    const renderFriendlyName = (name: string) => {
        if (name === 'givecard') {
            return 'GiveCard';
        } else if (name === 'pex') {
            return 'PEX';
        } else {
            return 'Debit Card';
        }
    };

    const renderInput = () => {
        const displayNums = placeHolder.map((num, index) => {
            return nums.length > index ? `${nums.charAt(index)} ` : num;
        });

        return displayNums;
    };

    return (
        <div
            className={classes.debitCard}
            style={
                selectedOption
                    ? {
                          backgroundColor: `#${selectedOption.asset_color}`
                      }
                    : {
                          background:
                              'radial-gradient(ellipse at bottom right, #7CA1E9 73%, #739BE8 0%)'
                      }
            }
        >
            <div className={classes.debitBody}>
                <img
                    src={selectedOption ? selectedOption.logo_asset : Logo}
                    alt="logo"
                />
                {debitCardOptions && debitCardOptions.length > 1 ? (
                    <FormControl>
                        <Select
                            disableUnderline
                            id="debit-card-select"
                            label="Debit Card"
                            labelId="debit-card-select-label"
                            onChange={handleChange}
                            value={debitType ? debitType : ''}
                            variant="standard"
                            inputProps={{
                                classes: {
                                    icon: classes.icon
                                },
                                MenuProps: {
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: `#${selectedOption?.asset_color}`
                                        }
                                    }
                                }
                            }}
                        >
                            {debitCardOptions.map((option) => (
                                <MenuItem key={option.type} value={option.type}>
                                    <Typography className={classes.debitTitle}>
                                        {renderFriendlyName(option.type)}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <span className={classes.debitTitle}>
                        {debitCardOptions.length === 0
                            ? 'Debit Card'
                            : debitCardOptions[0].friendly_name}
                    </span>
                )}
            </div>
            <div className={classes.numberContainer}>
                <span className={classes.debitNumber}>{renderInput()}</span>
            </div>
        </div>
    );
}
