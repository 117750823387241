import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CTAButton1 from 'components/_shared/buttons/CTAButton1';
import ExternalIDCard from 'assets/card-external_id.png';
import Input from './Input';
import LoadingCircle from 'components/_shared/LoadingCircle';

import {
    formatNumber,
    validateEmail,
    validatePhone
} from '../../PrivateInfo/utils';
import { isObjectEmpty } from '../../../../../util';

import { completeItemRequest } from 'redux/actions/homelessProfile';
import useUpdateHomelessInfo from 'hooks/useUpdateHomelessInfo';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        addInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            background: '#FFFFFF',
            borderRadius: '16px',
            margin: '24px 16px',
            padding: '16px 6% 16px 6%'
        },
        btn: {
            margin: '10px auto 20px auto',
            minWidth: '300px',
            fontSize: '16px'
        },
        btnDiv: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
            padding: '0 24px'
        },
        error: {
            backgroundColor: '#F5577A !important'
        },
        errorMessage: {
            color: 'red',
            fontSize: '12px',
            textAlign: 'center',
            width: '90%'
        },
        externalCard: {
            margin: '8px auto',
            maxWidth: '480px',
            width: '100%'
        },
        externalDescription: {
            fontSize: '16px',
            maxWidth: '480px',
            textAlign: 'center'
        },
        externalIDContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '16px'
        },
        externalTitle: {
            fontWeight: 800,
            textAlign: 'center'
        },
        success: {
            backgroundColor: '#7FE8B2 !important'
        },
        title: {}
    })
);

type AddInfoProps = {
    assignDebit?: (
        id: number,
        setLoading?: (loading: boolean) => void,
        setError?: (error: boolean) => void,
        setErrorMessage?: (message: string) => void
    ) => void;
    catchUpID?: number;
    externalID?: string;
    id?: number;
    nums?: string;
    setExternalID?: (id: string) => void;
    setType?: (type: string | null) => void;
    type: string;
    updateHomelessInfoField?: (field: string, value: string) => void;
    updateProfileCompletedItems?: (type: string) => void;
};

type InfoTypeTypes = {
    [key: string]: {
        component?: React.FC;
        inputs: {
            input_type: string;
            format?: (input: string) => string;
            validate?: (input: string) => boolean;
            label: string;
            placeholder: string;
        }[];
    };
};

type InputsStateType = {
    [key: string]: string;
};

const mapKeyToFieldName = (key: string) => {
    const fieldMap = {
        birthday: 'date_of_birth',
        email: 'email',
        first_name: 'pii_first_name',
        last_name: 'pii_last_name',
        member_id: 'pii_member_id',
        name: 'name',
        phone_number: 'phone'
    } as Record<string, string>;

    return fieldMap[key] || key;
};

const AddInfo: React.FC<AddInfoProps> = ({
    assignDebit,
    catchUpID,
    externalID,
    id,
    nums,
    setExternalID,
    setType,
    type,
    updateHomelessInfoField,
    updateProfileCompletedItems
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        error,
        isError,
        isLoading,
        isSuccess,
        reset,
        updateHomelessInfoRequest
    } = useUpdateHomelessInfo();

    const [assignError, setAssignError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inputs, setInputs] = useState<InputsStateType>({
        birthday: '',
        email: '',
        first_name: '',
        last_name: '',
        member_id: '',
        name: '',
        phone_number: ''
    });
    const [loading, setLoading] = useState(false);

    const infoTypes: InfoTypeTypes = {
        action_steps: {
            inputs: []
        },
        birthday: {
            inputs: [
                {
                    input_type: 'birthday',
                    label: 'Birthday',
                    placeholder: 'MM/DD/YYYY'
                }
            ]
        },
        contact_info: {
            inputs: [
                {
                    input_type: 'email',
                    label: 'Email',
                    placeholder: 'email@domain.com',
                    validate: (emailInput: string) => validateEmail(emailInput)
                },
                {
                    input_type: 'phone_number',
                    label: 'Phone Number',
                    placeholder: '(123) 456-7890',
                    validate: validatePhone
                }
            ]
        },
        external_id: {
            inputs: [
                {
                    input_type: 'external_id',
                    label: '',
                    placeholder: 'ABCD12VXYZ'
                }
            ]
        },
        full_name: {
            inputs: [
                {
                    input_type: 'first_name',
                    label: 'First Name',
                    placeholder: 'First Name'
                },
                {
                    input_type: 'last_name',
                    label: 'Last Name',
                    placeholder: 'Last Name'
                }
            ]
        },
        member_id: {
            inputs: [
                {
                    input_type: 'member_id',
                    label: 'Insurance ID',
                    placeholder: 'Insurance ID'
                }
            ]
        },
        name: {
            inputs: [
                {
                    input_type: 'name',
                    label: 'Nickname',
                    placeholder: 'Nickname'
                }
            ]
        },
        needs: {
            inputs: []
        },
        photo: {
            inputs: []
        },
        status: {
            inputs: []
        },
        supplemental_questions: {
            inputs: []
        }
    };

    const renderButtonClass = () => {
        if (isSuccess) {
            return classes.success;
        }
        if (assignError || isError) {
            return classes.error;
        }
    };

    const renderButtonText = () => {
        if (isSuccess) {
            return 'Saved!';
        }
        if (assignError || isError) {
            return 'Error!';
        }

        if (isLoading || loading) {
            return <LoadingCircle />;
        }

        if (type === 'external_id') {
            return 'Assign Card';
        } else {
            return 'Save Changes';
        }
    };

    const renderDisabled = () => {
        if (type === 'birthday') {
            return !inputs.birthday;
        }

        if (type === 'contact_info') {
            return (
                (!inputs.email && !inputs.phone_number) ||
                !validateEmail(inputs.email) ||
                !validatePhone(inputs.phone_number)
            );
        }

        if (type === 'external_id' && externalID!.length === 0) {
            return true;
        }

        if (type === 'full_name') {
            return !inputs.first_name && !inputs.last_name;
        }

        if (type === 'member_id') {
            return !inputs.member_id;
        }
    };

    const handleAssignWithExternalID = () => {
        if (assignDebit && id) {
            setLoading(true);
            assignDebit(id, setLoading, setAssignError, setErrorMessage);
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        input: { input_type: string }
    ) => {
        if (isError) {
            reset();
        }

        if (assignError) {
            setAssignError(false);
        }

        if (setExternalID) {
            setExternalID(e.target.value);
        } else {
            setInputs({
                ...inputs,
                [input.input_type]:
                    input.input_type === 'phone_number'
                        ? formatNumber(e.target.value.replace(/[^0-9]/g, ''))
                        : e.target.value
            });
        }
    };

    const handleSave = useCallback(() => {
        const updatedState = {} as InputsStateType;
        const _formData = new FormData();

        // Iterate over inputs and update state and form data
        Object.entries(inputs).forEach(([key, value]) => {
            if (value.length > 0) {
                updatedState[key] = value;
                const infoVal =
                    key === 'phone_number'
                        ? `+1${value.replace(/\D/g, '')}`
                        : value;

                _formData.append(
                    `samaritan_member[${mapKeyToFieldName(key)}]`,
                    infoVal
                );
            }
        });

        if (id && updateHomelessInfoField && !isObjectEmpty(updatedState)) {
            updateHomelessInfoRequest(id, _formData);

            for (const key in updatedState) {
                updateHomelessInfoField(key, updatedState[key]);
            }
        }

        if (catchUpID) {
            dispatch(completeItemRequest(catchUpID));
        }
    }, [
        catchUpID,
        dispatch,
        id,
        inputs,
        updateHomelessInfoField,
        updateHomelessInfoRequest
    ]);

    useEffect(() => {
        if (isSuccess && setType && updateProfileCompletedItems) {
            setTimeout(() => {
                updateProfileCompletedItems(type);
                setType(null);
            }, 2000);
        }
    }, [isSuccess, setType, type, updateProfileCompletedItems]);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                reset();
            }, 2000);
        }
    }, [isError, reset]);

    return (
        <div className={classes.addInfoContainer}>
            {type === 'external_id' && (
                <div className={classes.externalIDContainer}>
                    <span className={classes.externalTitle}>
                        Confirm Your card
                    </span>
                    <p className={classes.externalDescription}>
                        There are multiple cards with <b>{nums}</b> as the
                        card’s last 4 digits. To assign the right card, please
                        enter the ID printed on the back of your card.
                    </p>
                    <img
                        className={classes.externalCard}
                        src={ExternalIDCard}
                        alt="external id card"
                    />
                </div>
            )}
            {infoTypes[type].inputs?.map((input) => (
                <Input
                    inputType={input.input_type}
                    key={input.input_type}
                    label={input.label}
                    onChange={(e) => handleInputChange(e, input)}
                    placeholder={input.placeholder}
                    validator={input.validate}
                    value={inputs[input.input_type]}
                />
            ))}
            {assignError && (
                <span className={classes.errorMessage}>{errorMessage}</span>
            )}
            {isError && <span className={classes.errorMessage}>{error}</span>}
            <div className={classes.btnDiv}>
                <CTAButton1
                    className={`${classes.btn} ${renderButtonClass()}`}
                    disabled={
                        renderDisabled() ||
                        assignError ||
                        isError ||
                        isLoading ||
                        isSuccess
                    }
                    onClick={() => {
                        if (type === 'external_id') {
                            handleAssignWithExternalID();
                        } else {
                            handleSave();
                        }
                    }}
                >
                    {renderButtonText()}
                </CTAButton1>
            </div>
        </div>
    );
};

export default AddInfo;
