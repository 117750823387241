import { connect } from 'react-redux';
import actions from '../redux/actions';
import Intake from '../components/intake';

// state is object that holds reducers from combineReducers and hence the state in them
function mapStateToProps(state: any) {
    const { getApiError, getApiErrorResponse } = state.error;
    const { debitCardOptions } = state.debitCard;
    const { homelesses } = state.homelesses;
    const {
        intakeAgreed,
        intakeBirthDay,
        intakeDeploymentId,
        intakeEmail,
        intakeGoals,
        intakeGuideSteps,
        intakeGuideMessages,
        intakeIcebreaker,
        intakeId,
        intakeIsSubmitted,
        intakeIsSubmitting,
        intakeNeeds,
        intakePhoneNo,
        intakePrivateFirstName,
        intakePrivateFullName,
        intakePrivateLastName,
        intakePrivateMiddleName,
        intakeProfilePic,
        intakeProfileName,
        intakeProgressWidth,
        intakeQuestionsAndAnswers,
        intakeRelevantId,
        intakeStep,
        intakeTouchpoints,
        intakeTouchpointIsSubmitting,
        intakeTouchpointIsSubmitted,
        maxIntakeStep,
        minIntakeStep
    } = state.intake;
    const { layoutTitles } = state.samaritan;
    const { touchpointQuestions, isFetchingTouchpointQuestions } =
        state.touchpointQuestions;
    const { maxBonusAmount } = state.user;
    const {
        email,
        id,
        is_showing_intake_guides,
        members_enrolled,
        name,
        partner
    } = state.user.user;
    const {
        deployment,
        deployments,
        deployment_id,
        id: org_id,
        intake_steps
    } = state.user.user.partner || [];

    return {
        ctpEmail: email,
        ctpId: id,
        ctpName: name,
        ctpPartner: partner,
        debitCardOptions,
        deployment,
        deployments,
        deployment_id,
        getApiError,
        getApiErrorResponse,
        homelesses,
        intakeAgreed,
        intakeBirthDay,
        intakeDeploymentId,
        intakeEmail,
        intakeGoals,
        intakeGuideMessages,
        intakeGuideSteps,
        intakeIcebreaker,
        intakeId,
        intakeIsSubmitted,
        intakeIsSubmitting,
        intakeNeeds, // TODO: Remove this
        intakePhoneNo,
        intakePrivateFirstName,
        intakePrivateFullName,
        intakePrivateLastName,
        intakePrivateMiddleName,
        intakeProfileName,
        intakeProfilePic,
        intakeProgressWidth,
        intakeQuestionsAndAnswers,
        intakeRelevantId,
        intakeStep,
        intakeTouchpointIsSubmitted,
        intakeTouchpointIsSubmitting,
        intakeTouchpoints,
        intake_steps,
        isFetchingTouchpointQuestions,
        is_showing_intake_guides,
        layoutTitles,
        maxBonusAmount,
        maxIntakeStep,
        members_enrolled,
        minIntakeStep,
        orgId: org_id,
        touchpointQuestions
    };
}

const mapDispatchToProps = {
    decrementStep: actions.decrementIntakeStep,
    getTouchpointQuestions: actions.getTouchpointQuestionsRequest,
    incrementStep: actions.incrementIntakeStep,
    resetError: actions.resetApiError,
    // setIntakeAnswer: actions.setIntakeAnswer,
    setIntakeBirthDay: actions.setIntakeBirthDay,
    setIntakeDeploymentId: actions.setIntakeDeploymentId,
    setIntakeEmail: actions.setIntakeEmail,
    setIntakeGoals: actions.setIntakeGoals,
    setIntakeIcebreaker: actions.setIntakeIcebreaker,
    // setIntakeInterviewer: actions.setIntakeInterviewer,
    // setIntakeInterviewerId: actions.setIntakeInterviewerId,
    // setIntakeOrgId: actions.setIntakeOrgId,
    setIntakePhoneNo: actions.setIntakePhoneNo,
    setIntakePrivateFirstName: actions.setIntakePrivateFirstName,
    setIntakePrivateFullName: actions.setIntakePrivateFullName,
    setIntakePrivateLastName: actions.setIntakePrivateLastName,
    setIntakePrivateMiddleName: actions.setIntakePrivateMiddleName,
    setIntakeProfileName: actions.setIntakeProfileName,
    setIntakeProfilePicture: actions.setIntakeProfilePicture,
    setIntakeRelevantId: actions.setIntakeRelevantId,
    setIntakeTouchpoints: actions.setIntakeTouchpointAnswer,
    setLayoutTitles: actions.setLayoutTitles,
    setViewGuideMessage: actions.setViewGuideMessage,
    submitIntake: actions.submitIntake,
    submitIntakeTouchpoint: actions.submitIntakeTouchpoint,
    toggleIntakeAgreed: actions.toggleIntakeAgreed,
    updateUserSettings: actions.updateUserSettings
};

// TODO: Fix any types
export default connect(mapStateToProps, mapDispatchToProps)(Intake as any);
