import ReactGA from 'react-ga4';

import { DebitCardOptions } from 'types';

import Card from 'components/_shared/Card';

import { createStyles, makeStyles } from '@mui/styles';

const usestyles = makeStyles(() =>
    createStyles({
        card: {
            margin: '12px !important',
            width: '90%'
        },
        cardDescription: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 600,
            gap: '24px'
        },
        cardIcon: {
            height: '112px',
            width: 'auto'
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: 600,
            '& i': {
                color: '#999DFF',
                fontSize: '24px',
                fontWeight: 700,
                marginRight: '16px'
            }
        }
    })
);

type AssignBodyItemProps = {
    cardOption: DebitCardOptions;
    setAssignPopUp?: (assignView: boolean) => void;
    setDebitType: (type: string) => void;
    setReplaceView?: (replaceView: boolean) => void;
};

const AssignBodyItem: React.FC<AssignBodyItemProps> = ({
    cardOption,
    setAssignPopUp,
    setDebitType,
    setReplaceView
}) => {
    const classes = usestyles();

    const { card_asset, friendly_name: name, type } = cardOption;

    const handleClick = () => {
        ReactGA.event({
            category: 'Funds',
            action: 'Assigning Debit Card Click',
            label: type
        });

        setDebitType(type);

        if (setReplaceView) {
            setReplaceView(true);
        }

        if (setAssignPopUp) {
            setAssignPopUp(false);
        }
    };

    return (
        <Card active className={classes.card} onClick={handleClick}>
            <div className={classes.item}>
                <div className={classes.cardDescription}>
                    <img
                        className={classes.cardIcon}
                        src={card_asset}
                        alt="card"
                    />
                    <span>{name}</span>
                </div>
                <i className="far fa-chevron-right" />
            </div>
        </Card>
    );
};

export default AssignBodyItem;
