import CONSTANTS from '../constants/constants';

import { DebitCard, DebitCardOptions } from 'types';

import * as homelessAPI from 'api/homeless';
import * as giveCardAPI from 'api/givecard';
import * as pexAPI from 'api/pex';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

// function assignDebitCard() {
//     return {
//         type: CONSTANTS.ASSIGN_DEBIT_CARD
//     };
// }

function fetchDebitCardHistory() {
    return {
        type: CONSTANTS.FETCH_DEBIT_CARD_HISTORY
    };
}

function fetchDebitCardInfo() {
    return {
        type: CONSTANTS.GET_DEBIT_CARD_INFO
    };
}

function fetchDebitCardOptions() {
    return {
        type: CONSTANTS.GET_DEBIT_CARD_OPTIONS
    };
}

function setDebitCardHistory(response: any) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_HISTORY,
        payload: response
    };
}

function setDebitCardFullHistory(response: any) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_FULL_HISTORY,
        payload: response
    };
}

export function setDebitCardInfo(response: DebitCard) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_INFO,
        payload: response
    };
}

function setDebitCardOptions(response: DebitCardOptions) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_OPTIONS,
        payload: response
    };
}

// function updateDebitCardPin() {
//     return {
//         type: CONSTANTS.UPDATE_DEBIT_CARD_PIN
//     };
// }

// export function assignDebitCardRequest(
//     memberID: number,
//     type: string,
//     last4: number
// ) {
//     return async (dispatch: Function) => {
//         dispatch(assignDebitCard());

//         try {
//             const assignFunction =
//                 type === 'give_card'
//                     ? giveCardAPI.assignGiveCard(memberID, last4)
//                     : pexAPI.assignPexCard(memberID, last4);

//             console.log('type', type);
//             console.log('assign function', assignFunction);

//             const response = await assignFunction;
//             const responseJson = await response.json();

//             console.log('assign response', responseJson);

//             return dispatch(setDebitCardInfo(responseJson));
//         } catch (error: any) {
//             return handleSamaritanApiError(error, dispatch);
//         }
//     };
// }

export function fetchDebitCardOptionsRequest() {
    return async (dispatch: Function) => {
        dispatch(fetchDebitCardOptions());

        try {
            const response = await giveCardAPI.fetchDebitTypes();
            handleSamaritanApiResponse(response.clone(), dispatch);

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();
            const cardOptions = data.remote_copy?.card_options;

            if (cardOptions) {
                dispatch(setDebitCardOptions(cardOptions));
            }
        } catch (error: any) {
            handleSamaritanApiError(
                {
                    message: `Failed to fetch debit card options: ${error}`
                },
                dispatch
            );
        }
    };
}

export function getDebitCardHistory(
    memberID: number,
    type: string,
    full?: boolean
) {
    return (dispatch: Function) => {
        dispatch(fetchDebitCardHistory());

        const fetchFunction =
            type === 'givecard'
                ? giveCardAPI.fetchGiveCardHistory(memberID, full || false)
                : pexAPI.fetchPexHistory(memberID, full || false);

        return fetchFunction
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: any) => {
                const actionCreator = full
                    ? setDebitCardFullHistory
                    : setDebitCardHistory;

                return dispatch(actionCreator(responseJson.message));
            })
            .catch((error: any) => {
                if (error) {
                    return handleSamaritanApiError(error, dispatch);
                } else {
                    return handleSamaritanApiError(
                        {
                            message: `Failed to fetch ${type} history for member: ${memberID}`
                        },
                        dispatch
                    );
                }
            });
    };
}

export function getDebitCardInfo(memberID: number) {
    return (dispatch: Function) => {
        dispatch(fetchDebitCardInfo());

        return homelessAPI
            .fetchHomelessInfo(memberID)
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: { samaritan_member: { card: DebitCard } }) => {
                return dispatch(
                    setDebitCardInfo(responseJson.samaritan_member.card)
                );
            })
            .catch((_error: any) => {
                if (_error) {
                    return handleSamaritanApiError(_error, dispatch);
                } else {
                    return handleSamaritanApiError(
                        {
                            message: `Failed to fetch GiveCard/PEX info for member: ${memberID}`
                        },
                        dispatch
                    );
                }
            });
    };
}

// export function updateDebitCardPinRequest(
//     memberID: number,
//     type: string,
//     pin: number
// ) {
//     return (dispatch: Function) => {
//         dispatch(updateDebitCardPin());

//         const updateFunction =
//             type === 'give_card'
//                 ? giveCardAPI.updateGiveCardPin(memberID, pin)
//                 : pexAPI.updatePexPin(memberID, pin);

//         return updateFunction
//             .then((response: any) =>
//                 handleSamaritanApiResponse(response, dispatch)
//             )
//             .catch((error: any) => {
//                 return handleSamaritanApiError(error, dispatch);
//             });
//     };
// }

export function updateDebitCardBalance(balance: number) {
    return {
        type: CONSTANTS.UPDATE_DEBIT_CARD_BALANCE,
        payload: { balance }
    };
}
