import Card from 'components/_shared/Card';
import DebitPreview from 'assets/DebitPreview.png';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    card: {
        backgroundColor: '#EFEFFC !important',
        padding: '20px 0px !important',
        width: '100%'
    },
    cardBody: {
        position: 'absolute',
        bottom: '-25%',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        margin: 'auto',
        width: '70%'
    },
    cardBodyContent: {
        color: '#A6A6BF',
        fontSize: '20px',
        fontWeight: 300,
        textAlign: 'center'
    },
    cardBodyHeader: {
        fontSize: '20px',
        fontWeight: 800,
        margin: 'auto',
        textAlign: 'center'
    },
    captureButton: {
        borderRadius: '36px',
        color: '#7378E8',
        fontSize: '22px',
        fontWeight: 700,
        padding: '16px 24px',
        textTransform: 'none'
    },
    gradient: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        margin: '24px 0 64px 0',
        padding: '20px',
        '&::after': {
            position: 'absolute',
            left: 0,
            top: 0,
            display: 'inline-block',
            background:
                'linear-gradient(180deg, rgba(240, 240, 255, 0) 9.09%, #F0F0FF 52.58%, #F0F0FF 100%)',
            content: '""',
            height: '100%',
            width: '100%'
        },
        '& img': {
            position: 'absolute',
            display: 'block',
            maxWidth: '480px',
            minWidth: '300px'
        }
    },
    loadingPreview: {
        display: 'block',
        background: '#739BE8',
        borderRadius: '16px',
        height: '240px',
        maxWidth: '480px',
        minWidth: '300px',
        width: '100%'
    }
}));

type AssignCardProps = {
    assignCard: () => void;
};

const AssignCard: React.FC<AssignCardProps> = ({ assignCard }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <div className={classes.gradient}>
                <img src={DebitPreview} alt="Debit Preview" />
                <div className={classes.loadingPreview} />
                <div className={classes.cardBody}>
                    <span className={classes.cardBodyHeader}>
                        Assign a Card
                    </span>
                    <span className={classes.cardBodyContent}>
                        Allow your Member to spend funds immediately and
                        flexibly by assigning them a debit card.
                    </span>
                    <Button
                        className={classes.captureButton}
                        onClick={assignCard}
                    >
                        Assign Card
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default AssignCard;
