import { useEffect, useState } from 'react';

import PhotoCrop from './components/PhotoCrop';
import QRContent from './components/QRContent';
import WebcamContent from './components/WebcamContent';

import { createSessionToken } from '../../../api/photo';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            background: '#FBFBFF',
            border: 'none',
            borderBottom: '1px solid #A6A6BF',
            borderRadius: '0',
            color: '#1F1F51',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
            padding: '20px 36px',
            textTransform: 'capitalize',
            width: '100%',
            '&:hover': {
                backgroundColor: '#F2F2FD'
            },
            '& input[type="file"]': {
                display: 'none'
            }
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            width: '100%',
            zIndex: 7,
            '@media (max-width: 768px)': {
                left: 0,
                width: '100%'
            }
        },
        modalContent: {
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            color: '#000',
            maxWidth: '80%',
            maxHeight: '80%',
            minWidth: '30%',
            overflow: 'auto'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        computerBtn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0',
            width: '100%',
            '& label': {
                cursor: 'pointer'
            }
        },
        fileUpload: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: '20px 0px',
            width: '100%'
        }
    })
);

type PhotoPopupProps = {
    handleModalClick: () => void;
    // profilePicture: any;
    intakeStyle?: boolean;
    setProfilePicture: (profilePicture: any) => void;
    setGuide?: (guide: boolean) => void;
};

const PhotoPopup: React.FC<PhotoPopupProps> = ({
    handleModalClick,
    intakeStyle,
    // profilePicture,
    setProfilePicture,
    setGuide
}) => {
    const classes = useStyles();

    const [computerImage, setComputerImage] = useState<File | null>(null);
    const [qrCode, setQRCode] = useState('');
    const [showQR, setShowQR] = useState(false);
    const [showWebcam, setShowWebcam] = useState(false);
    const [token, setToken] = useState('');

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const img = e.target.files?.[0];

        if (img) {
            setComputerImage(img);
        }

        if (setGuide) {
            setGuide(false);
        }
    };

    const handleMobileClick = async () => {
        const { intake_session_token } = await createSessionToken();

        if (setGuide) {
            setGuide(false);
        }

        setShowQR(true);
        setToken(intake_session_token.token);
        setQRCode(intake_session_token.qr_code);
    };

    const handleWebcamClick = () => {
        if (setGuide) {
            setGuide(false);
        }

        setShowWebcam(true);
    };

    useEffect(() => {
        if (showQR) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showQR]);

    return (
        <div
            className={classes.modal}
            style={
                intakeStyle
                    ? { left: '300px', width: 'calc(100% - 300px)' }
                    : {}
            }
        >
            <div className={classes.modalContent}>
                {!showQR && !showWebcam && !computerImage && (
                    <div className={classes.content}>
                        <div
                            className={`${classes.btn} ${classes.computerBtn}`}
                        >
                            <label className={classes.fileUpload}>
                                From Computer
                                <input
                                    accept="image/*"
                                    id="photo-upload"
                                    onChange={(e) => handleImageUpload(e)}
                                    title=" "
                                    type="file"
                                />
                            </label>
                        </div>
                        <Button
                            className={`${classes.btn}`}
                            onClick={() => handleMobileClick()}
                        >
                            From Mobile
                        </Button>
                        <Button
                            className={`${classes.btn}`}
                            onClick={() => handleWebcamClick()}
                        >
                            From Webcam
                        </Button>
                        <Button
                            className={classes.btn}
                            onClick={() => handleModalClick()}
                        >
                            Cancel
                        </Button>
                    </div>
                )}
                {computerImage && (
                    <PhotoCrop
                        handleModalClick={handleModalClick}
                        image={computerImage}
                        setProfilePicture={setProfilePicture}
                    />
                )}
                {showQR && (
                    <QRContent
                        handleModalClick={handleModalClick}
                        // profilePicture={profilePicture}
                        setProfilePicture={setProfilePicture}
                        qrCode={qrCode}
                        setShowQR={setShowQR}
                        token={token}
                    />
                )}
                {showWebcam && (
                    <WebcamContent
                        handleModalClick={handleModalClick}
                        setProfilePicture={setProfilePicture}
                        setShowWebcam={setShowWebcam}
                    />
                )}
            </div>
        </div>
    );
};

export default PhotoPopup;
