import { useEffect, useState } from 'react';

import {
    DebitCard,
    DebitCardOptions,
    Homeless,
    Needs,
    MemberHistory,
    PexHistory
} from 'types';

import Balance from './Balance';
import Card from '../CaseManagement/components/Card';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    fundsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '60px',
        padding: '30px 12% 60px 12%'
    }
}));

type FundsProps = {
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    getDebitCardHistory: (
        id: number,
        type: string,
        fullHistory?: boolean
    ) => any;
    giveAutoTransfer: boolean;
    holder: Homeless;
    isFetchingDebitHistory: boolean;
    isFetchingDebitInfo: boolean;
    memberPayments: any[];
    needs: Needs[];
    pexAutoTransfer: boolean;
};

const Funds: React.FC<FundsProps> = ({
    debitCardOptions,
    debitInfo,
    getDebitCardHistory,
    giveAutoTransfer,
    holder,
    isFetchingDebitHistory,
    isFetchingDebitInfo,
    memberPayments,
    needs,
    pexAutoTransfer
}) => {
    const classes = useStyles();

    const { balance } = holder;
    const hasDebitCard =
        debitInfo && debitInfo.card_last4 && debitInfo.card_last4.length > 0
            ? true
            : false;

    const [fullMemberHistory, setFullMemberHistory] = useState<MemberHistory[]>(
        []
    );
    const [fullCardHistory, setFullCardHistory] = useState<PexHistory[]>([]);
    const [memberBalance, setMemberBalance] = useState(balance);
    const [recentMemberHistory, setRecentMemberHistory] = useState<
        null | MemberHistory[]
    >(memberPayments);
    const [recentCardHistory, setRecentCardHistory] = useState<PexHistory[]>(
        []
    );

    const balanceProps = {
        balance: memberBalance,
        debitInfo,
        debitCardOptions,
        fullMemberHistory,
        fullCardHistory,
        getDebitCardHistory,
        giveAutoTransfer,
        hasDebitCard,
        holder,
        isFetchingDebitHistory,
        isFetchingDebitInfo,
        memberBalance,
        needs,
        pexAutoTransfer,
        recentMemberHistory,
        recentCardHistory,
        setFullMemberHistory,
        setRecentMemberHistory,
        setMemberBalance,
        setRecentCardHistory,
        setFullCardHistory
    };

    useEffect(() => {
        if (hasDebitCard && (giveAutoTransfer || pexAutoTransfer)) {
            getDebitCardHistory(holder.id, debitInfo.card_type || 'pex', false)
                .then((data: { payload: PexHistory[]; type: string }) => {
                    setRecentCardHistory(data.payload);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    }, [
        debitInfo,
        getDebitCardHistory,
        giveAutoTransfer,
        hasDebitCard,
        holder.id,
        pexAutoTransfer
    ]);

    return (
        <div className={classes.fundsContainer}>
            <Card
                info
                funds={!pexAutoTransfer || (pexAutoTransfer && !hasDebitCard)}
                debit={pexAutoTransfer && hasDebitCard}
            />
            <Balance {...balanceProps} />
        </div>
    );
};

export default Funds;
