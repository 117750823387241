import { useCallback, useEffect, useRef, useState } from 'react';

import { EditButton } from '../buttons';
import FormInput from '../../../../_shared/FormInput';
import Underline from './Underline';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        editIcon: {
            height: 'inherit',
            width: 'inherit'
        },
        editIconContainer: {
            cursor: 'pointer',
            height: 'auto',
            margin: '0 0 0 15px',
            width: '1.5rem'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        inputContainer: {
            marginBottom: '40px'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 0px 0px',
            resize: 'none',
            width: '100%',
            '&:focus': {
                outline: 'none !important'
            },
            '&:placeholder-shown': {
                fontStyle: 'italic'
            }
        },
        saveInputBtnContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingBottom: '15px',
            '& button': {
                cursor: 'pointer',
                fontFamily: 'Manrope',
                fontSize: '16px',
                fontWeight: '800'
            }
        },
        underline: {
            backgroundColor: '#E3E4FA',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            marginBottom: '20px'
        }
    })
);

type NameInputProps = {
    inputTitleClass: (value: string) => string;
    intakePrivateFirstName: string;
    intakePrivateFullName: string;
    intakePrivateLastName: string;
    remoteCopy: {
        label: string;
        placeholder: string;
        required: boolean;
    };
    setIntakePrivateFirstName: (value: string) => void;
    setIntakePrivateFullName: (value: string) => void;
    setIntakePrivateLastName: (value: string) => void;
};

const NameInput: React.FC<NameInputProps> = ({ remoteCopy, ...props }) => {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);
    const firstNameRef = useRef<HTMLTextAreaElement>(null);
    const lastNameRef = useRef<HTMLTextAreaElement>(null);

    const {
        inputTitleClass,
        intakePrivateFirstName,
        intakePrivateFullName,
        intakePrivateLastName,
        setIntakePrivateFirstName,
        setIntakePrivateFullName,
        setIntakePrivateLastName
    } = props;

    const { label, required } = remoteCopy;

    const [firstName, setFirstName] = useState(intakePrivateFirstName);
    const [fullNameInput, setFullNameInput] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [lastName, setLastName] = useState(intakePrivateLastName);
    const [savedInput, setSavedInput] = useState(
        intakePrivateFullName.length > 0
    );

    const combineName = (first: string, last: string) =>
        `${first} ${last}`.trim();

    const handleEditClick = () => {
        setSavedInput(false);
    };

    const handleFirstNameInputClick = () => {
        setFullNameInput(false);
        setIsActive(true);
        setSavedInput(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        if (e.target.id === 'first-name-input') {
            setFirstName(value);
            setIntakePrivateFirstName(value);
        } else {
            setLastName(value);
            setIntakePrivateLastName(value);
        }
    };

    const handleSaveClick = useCallback(() => {
        setSavedInput(true);
    }, [setSavedInput]);

    useEffect(() => {
        if (
            intakePrivateFirstName &&
            intakePrivateLastName &&
            intakePrivateFullName !==
                combineName(intakePrivateFirstName, intakePrivateLastName)
        ) {
            setIntakePrivateFullName(
                combineName(intakePrivateFirstName, intakePrivateLastName)
            );
        }
    }, [
        intakePrivateFirstName,
        intakePrivateFullName,
        intakePrivateLastName,
        setIntakePrivateFullName
    ]);

    useEffect(() => {
        if (firstName === '' || lastName === '') {
            setIntakePrivateFullName('');
        }
    }, [firstName, lastName, setIntakePrivateFullName]);

    useEffect(() => {
        if (firstNameRef.current && isActive) {
            firstNameRef.current.focus();
        }
    }, [firstName, isActive]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                if (firstName && lastName) {
                    handleSaveClick();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [firstName, handleSaveClick, lastName]);

    return (
        <div className={classes.inputContainer} ref={containerRef}>
            {savedInput && (
                <span className={inputTitleClass(intakePrivateFullName)}>
                    {required ? (
                        <span style={{ color: '#A80A2D' }}>*</span>
                    ) : null}{' '}
                    {label}
                </span>
            )}
            {fullNameInput && !savedInput && (
                <>
                    <span className={inputTitleClass(intakePrivateFullName)}>
                        {required ? (
                            <span style={{ color: '#A80A2D' }}>*</span>
                        ) : null}{' '}
                        {label}
                    </span>
                    <FormInput
                        autoComplete="on"
                        className={classes.inputFields}
                        name={'full name'}
                        onChange={(e) => e.preventDefault()}
                        onClick={handleFirstNameInputClick}
                        placeholder={'First Last'}
                        value={intakePrivateFullName}
                    />
                </>
            )}
            {!fullNameInput && !savedInput && (
                <>
                    <span
                        className={inputTitleClass(
                            intakePrivateFirstName ? intakePrivateFirstName : ''
                        )}
                    >
                        <span style={{ color: '#A80A2D' }}>*</span> First Name
                    </span>
                    <FormInput
                        autoComplete="on"
                        className={classes.inputFields}
                        id={'first-name-input'}
                        name={'first-name'}
                        onChange={handleInputChange}
                        onClick={() => setSavedInput(false)}
                        placeholder={'First Name'}
                        ref={firstNameRef}
                        value={firstName ? firstName : ''}
                    />
                    <Underline disabled={firstName === ''} />
                    <span
                        className={inputTitleClass(
                            intakePrivateLastName ? intakePrivateLastName : ''
                        )}
                    >
                        <span style={{ color: '#A80A2D' }}>*</span> Last Name
                    </span>
                    <FormInput
                        autoComplete="on"
                        className={classes.inputFields}
                        id={'last-name-input'}
                        name={'last-name'}
                        onChange={handleInputChange}
                        onClick={() => setSavedInput(false)}
                        placeholder={'Last Name'}
                        ref={lastNameRef}
                        value={lastName ? lastName : ''}
                    />
                    <Underline disabled={lastName === ''} />
                </>
            )}
            {savedInput && (
                <EditButton
                    handleEditClick={handleEditClick}
                    inputValState={intakePrivateFullName}
                    saveInputState={savedInput}
                />
            )}
            {!savedInput && fullNameInput && <Underline />}
        </div>
    );
};

export default NameInput;