import CONSTANTS from '../constants/constants';

import * as api from '../../api/homeless';

import { getHomelessGoals } from './goals';
import { getHomelessGoalActions } from './goal_actions';
import { getHomelessNeeds } from './needs';
import { getV3HomelessTouchpoints } from './touchpoints';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

function fetchHistoryFilters() {
    return {
        type: CONSTANTS.FETCH_HISTORY_FILTERS
    };
}

function fetchHomelessHistory() {
    return {
        type: CONSTANTS.FETCH_MEMBER_HISTORY
    };
}

function fetchHomelessInfo() {
    return {
        type: CONSTANTS.GET_HOMELESS_INFO
    };
}

function fetchHomelessLifeStory() {
    return {
        type: CONSTANTS.FETCH_HOMELESS_LIFESTORY
    };
}

// TODO: Fix any types
export function getHistoryFilters() {
    return (dispatch: Function) => {
        dispatch(fetchHistoryFilters());

        return api
            .fetchHistoryFilters()
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: any) => {
                dispatch(setHistoryFilters(responseJson));

                return responseJson;
            })
            .catch((error: any) => handleSamaritanApiError(error, dispatch));
    };
}

export function getHomelessHistory(memberId: number) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessHistory());
        return api
            .fetchHomelessHistory(memberId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: any) => {
                dispatch(setHomelessHistory(responseJson.member_histories));

                return responseJson.member_histories;
            })
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
}

export function getHomelessInfo(
    sessionToken: string,
    apiToken: string,
    _beaconId: string,
    memberId: number
) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessInfo());
        return api
            .fetchHomelessInfo(memberId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { samaritan_member: any }) => {
                dispatch(setHomelessInfo(responseJson.samaritan_member));
                return responseJson.samaritan_member;
            })
            .then((homelessInfo: { id: number }) => {
                dispatch(getHomelessGoals(sessionToken, homelessInfo.id));
                dispatch(getHomelessGoalActions(sessionToken, homelessInfo.id));
                dispatch(getV3HomelessTouchpoints(apiToken, homelessInfo.id));
                return dispatch(
                    getHomelessNeeds(sessionToken, homelessInfo.id)
                );
            })
            .catch((_error: any) => {
                dispatch(setHomelessInfoError());
                return handleSamaritanApiError(
                    { message: 'Failed to fetch homeless info' },
                    dispatch
                );
            });
    };
}

export const getHomelessLifeStory = (memberId: number) => {
    return (dispatch: Function) => {
        dispatch(fetchHomelessLifeStory());
        return api
            .fetchHomelessLifeStory(memberId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { holder_life_updates: any }) => {
                dispatch(
                    setHomelessLifeStory(responseJson.holder_life_updates)
                );
                return responseJson;
            })
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
};

export function resetHomelessInfo() {
    return {
        type: CONSTANTS.RESET_HOMELESS_INFO
    };
}

function setHistoryFilters(response: any) {
    return {
        type: CONSTANTS.SET_HISTORY_FILTERS,
        payload: response
    };
}

function setHomelessHistory(response: any) {
    return {
        type: CONSTANTS.SET_MEMBER_HISTORY,
        payload: response
    };
}

function setHomelessInfo(response: any) {
    return (dispatch: Function) => {
        dispatch({
            type: CONSTANTS.SET_HOMELESS_INFO,
            payload: response
        });
    };
}

function setHomelessInfoError() {
    return {
        type: CONSTANTS.SET_HOMELESS_INFO_ERROR
    };
}

function setHomelessLifeStory(response: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_LIFESTORY,
        payload: response
    };
}

export function setProfileCompletedItems(completedItems: string[]) {
    return {
        type: CONSTANTS.SET_PROFILE_COMPLETED_ITEMS,
        payload: completedItems
    };
}

export function updateBonusAmount(response: any) {
    return {
        type: CONSTANTS.UPDATE_BONUS_AMOUNT,
        payload: response
    };
}

export function updateHomelessBalance(balance: number) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_BALANCE,
        payload: { balance }
    };
}

export function updateHomelessCardBalance(balance: number) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_CARD_BALANCE,
        payload: { balance }
    };
}

export function updateHomelessInfoField(field: string, value: string) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_INFO_FIELD,
        payload: { field, value }
    };
}

function updateHomelessLifeStory(response: any) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_LIFESTORY,
        payload: response
    };
}

export const updateHomelessLifeStoryRequest = (data: any) => {
    return (dispatch: Function) => {
        dispatch(fetchHomelessLifeStory());
        return api
            .updateHomelessLifeStory(data)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { holder_life_update: any }) => {
                dispatch(
                    updateHomelessLifeStory(responseJson.holder_life_update)
                );
                return responseJson;
            })
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
};

export function updateProfileCompletedItems(item: string) {
    return {
        type: CONSTANTS.UPDATE_PROFILE_COMPLETED_ITEMS,
        payload: item
    };
}