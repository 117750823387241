import ReactGA from 'react-ga4';
import { useHistory, useRouteMatch } from 'react-router';

import { calculateDateDifference, renderClasses } from '../../utils';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    actionButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '10px',
        '@media (max-width: 768px)': {
            flexDirection: 'column'
        }
    },
    actionButton: {
        color: '#FFFFFF',
        fontSize: '16px',
        height: '34px',
        lineHeight: '21px',
        width: '34px'
    },
    assessmentContainer: {
        position: 'relative',
        '& button': {
            position: 'absolute',
            right: '0',
            top: '0'
        }
    },
    assessmentPill: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E7FEEB',
        borderRadius: '200px',
        color: '#0AA823',
        height: '34px',
        padding: '0 42px 0 16px',
        textWrap: 'nowrap',
        width: 'max-content',
        '& i': {
            marginRight: '6px'
        }
    },
    dueDateContainer: {
        position: 'relative',
        '& button': {
            position: 'absolute',
            right: '0',
            top: '0'
        }
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FEF3E7',
        borderRadius: '200px',
        color: '#7A4406',
        height: '34px',
        padding: '0 42px 0 16px',
        textWrap: 'nowrap',
        width: 'max-content',
        '& i': {
            marginRight: '6px'
        }
    },
    dueDateWarning: {
        backgroundColor: '#FEE7EC',
        color: '#A80A2D'
    },
    buttonWarning: {
        backgroundColor: '#E87F7F'
    },
    dueDate: {
        backgroundColor: '#F7C354'
    },
    edit: {
        backgroundColor: '#999DFF'
    },
    complete: {
        backgroundColor: '#7FE8B2 !important',
        color: '#FFFFFF !important'
    },
    cancelled: {
        backgroundColor: '#A6A6BF !important',
        color: '#FFFFFF !important'
    }
}));

type ActionButtonProps = {
    action: any;
    date: string;
    setActionStepID: (id: number) => void;
    setCompleteActionStep: (complete: boolean) => void;
    setEdit: (edit: boolean) => void;
    setHasQOLMeasures: (hasQOLMeasures: boolean) => void;
    setReminder: (reminder: boolean) => void;
    touchpointQuestionQOLs: number[];
    updatedActionIDs: number[];
};

const ActionButtons: React.FC<ActionButtonProps> = ({
    action,
    date,
    setActionStepID,
    setCompleteActionStep,
    setEdit,
    setHasQOLMeasures,
    setReminder,
    touchpointQuestionQOLs,
    updatedActionIDs
}) => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const { action_type, id, quality_of_life_measures, status } = action;
    const { buttonClass, dateClass } = renderClasses(classes, date);
    const isUpdated = updatedActionIDs.includes(id);

    const handleAssessmentClick = () => {
        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Action Step',
            eventAction: 'QOL Assessment',
            eventLabel: 'QOL Assessment'
        });

        const fromState = {
            from: 'profileQOLAssessment',
            id
        };

        history.push(`${match.url}/qol`, fromState);
    };

    const handleCompleteClick = () => {
        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Action Step',
            eventAction: 'Complete',
            eventLabel: 'Complete Action Step'
        });

        const hasQOLMeasures =
            quality_of_life_measures.length > 0
                ? quality_of_life_measures.some((qol: { id: number }) =>
                      touchpointQuestionQOLs.includes(qol.id)
                  )
                : false;

        setHasQOLMeasures(hasQOLMeasures);
        setCompleteActionStep(true);
        setActionStepID(id);
    };

    const handleEditClick = () => {
        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Action Step',
            eventAction: 'Edit',
            eventLabel: 'Edit Action Step'
        });

        setEdit(true);
        setActionStepID(id);
    };

    const handleReminderClick = () => {
        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Action Step',
            eventAction: 'Reminder',
            eventLabel: 'Set Action Step Reminder'
        });

        setReminder(true);
        setActionStepID(id);
    };

    return (
        <div className={classes.actionButtonContainer}>
            {!isUpdated ? (
                <>
                    <div className={classes.dueDateContainer}>
                        <div className={dateClass}>
                            <i className="fal fa-clock" />
                            <span style={{ width: 'max-content' }}>
                                Due: {calculateDateDifference(date)}
                            </span>
                        </div>
                        <IconButton
                            aria-label="due date"
                            className={buttonClass}
                            onClick={() => handleReminderClick()}
                            size="large"
                        >
                            <i className="fas fa-bell" />
                        </IconButton>
                    </div>
                    <IconButton
                        aria-label="edit"
                        className={`${classes.actionButton} ${classes.edit}`}
                        onClick={() => handleEditClick()}
                        size="large"
                    >
                        <i className="fas fa-pencil" />
                    </IconButton>
                    {action_type === 'lifecarable' ? (
                        <div className={classes.assessmentContainer}>
                            <div className={classes.assessmentPill}>
                                <span style={{ width: 'max-content' }}>
                                    Assessment
                                </span>
                            </div>
                            <IconButton
                                aria-label="qol assessment"
                                className={`${classes.actionButton} ${classes.complete}`}
                                onClick={() => handleAssessmentClick()}
                                size="large"
                            >
                                <i className="fas fa-clipboard-list" />
                            </IconButton>
                        </div>
                    ) : (
                        <IconButton
                            aria-label="complete"
                            className={`${classes.actionButton} ${classes.complete}`}
                            onClick={() => handleCompleteClick()}
                            size="large"
                        >
                            <i className="fas fa-check" />
                        </IconButton>
                    )}
                </>
            ) : (
                <>
                    {status === 'completed' && (
                        <IconButton
                            aria-label="completed"
                            className={`${classes.actionButton} ${classes.complete}`}
                            disabled
                            size="large"
                        >
                            <i className="fas fa-check" />
                        </IconButton>
                    )}
                    {status === 'cancelled' && (
                        <IconButton
                            aria-label="cancelled"
                            className={`${classes.actionButton} ${classes.cancelled}`}
                            disabled
                            size="large"
                        >
                            <i className="fas fa-times" />
                        </IconButton>
                    )}
                </>
            )}
        </div>
    );
};

export default ActionButtons;
