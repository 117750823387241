import classNames from 'classnames';

import Card from 'components/_shared/Card';
import { ManageDebit, MembershipCard } from 'assets/index';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionCard: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '8px',
            height: '280px',
            width: '100%',
            maxWidth: '180px',
            textAlign: 'center'
        },
        activeCard: {
            cursor: 'pointer',
            '&:active': {
                transform: 'scale(0.98)'
            }
        },
        assignTitle: {
            color: '#1F204E',
            fontWeight: 700,
            fontSize: '20px'
        },
        checkCircle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#7FE8B2',
            borderRadius: '35px',
            color: '#FFFFFF',
            height: '64px',
            width: '64px'
        },
        debitCard: {
            height: '100px',
            width: 'auto'
        },
        memberCard: {
            height: '120px',
            width: 'auto'
        },
        overlay: {
            position: 'absolute',
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#00000099',
            borderRadius: '16px',
            color: '#7FE8B2',
            fontWeight: 800,
            fontSize: '24px',
            height: '100%',
            width: '100%'
        }
    })
);

type CardType = {
    [key: string]: {
        className: string;
        image: string;
        subtitle: string;
        title: string;
    };
};

type OptionalActionCardProps = {
    assignDebit?: boolean;
    handleCardClick?: () => void;
    memberName: string;
    type: string;
};

const OptionalActionCard: React.FC<OptionalActionCardProps> = ({
    assignDebit,
    handleCardClick,
    memberName,
    type
}) => {
    const classes = useStyles();

    const cardClass = classNames({
        [classes.actionCard]: true,
        [classes.activeCard]: typeof assignDebit !== 'undefined' && !assignDebit
    });

    const cardType: CardType = {
        debit: {
            className: classes.debitCard,
            image: ManageDebit,
            subtitle: `${memberName} can spend funds flexibly and immediately`,
            title: 'Assign Debit Card'
        },
        membership: {
            className: classes.memberCard,
            image: MembershipCard,
            subtitle: `Access ${memberName} profile with one quick tap`,
            title: 'Link Membership Card'
        }
    };

    const { className, image, subtitle, title } = cardType[type];

    return (
        <Card
            className={cardClass}
            onClick={handleCardClick ? handleCardClick : () => {}}
        >
            {assignDebit && type === 'debit' && (
                <div className={classes.overlay}>
                    <span className={classes.checkCircle}>
                        <i className="fas fa-check" />
                    </span>
                    Completed
                </div>
            )}
            {type === 'membership' && (
                <div className={classes.overlay} style={{ color: '#FFFFFF ' }}>
                    Coming soon!
                </div>
            )}
            <span className={classes.assignTitle}>{title}</span>
            <img src={image} alt="manage-action" className={className} />
            <span style={{ fontSize: '14px' }}>{subtitle}</span>
        </Card>
    );
};

export default OptionalActionCard;
