import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { renderDateTitle, renderTypeTitle } from '../utils';

const useStyles = makeStyles((theme) => ({
    selectorContainer: {
        display: 'flex',
        gap: '20px',
        margin: '20px 0'
    },
    selectorBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: '16px',
        fontWeight: '700',
        color: '#1F2152',
        minWidth: 'fit-content',
        padding: '10px 20px',
        border: '1px solid #E3E3E3',
        borderRadius: '100px 0 0 100px',
        textTransform: 'none',
        '& span': {
            gap: '20px'
        }
    },
    selectorBtnRight: {
        borderRadius: '0 100px 100px 0',
        height: '100%'
    },
    selectorBtnActive: {
        backgroundColor: '#7378E8',
        color: '#FFFFFF',
        border: '1px solid #7378E8'
    },
    splitButton: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Selectors = ({
    dateButtonActive,
    setDateButtonActive,
    typeButtonActive,
    setTypeButtonActive,
    filterDate,
    filterType,
    setFilterEndDate,
    setFilterDate,
    setFilterType,
    setViewFilter
}) => {
    const classes = useStyles();

    const handleCancelClick = (filter, setActive) => {
        if (filter === 'filter date') {
            setFilterEndDate(null);
            setFilterDate(null);
        }

        if (filter === 'filter type') {
            setFilterType('all');
        }

        setActive(false);
        setViewFilter(null);
    };

    const handleSelectClick = (filter, setActive) => {
        setViewFilter(filter);
        setActive(true);
    };

    const renderButton = (label, filter, isActive, setActive) => (
        <div className={classes.splitButton}>
            <Button
                className={
                    isActive
                        ? `${classes.selectorBtn} ${classes.selectorBtnActive}`
                        : classes.selectorBtn
                }
                onClick={() => handleSelectClick(filter, setActive)}
            >
                <span>{label}</span>
            </Button>
            <Button
                className={
                    isActive
                        ? `${classes.selectorBtnRight} ${classes.selectorBtnActive}`
                        : `${classes.selectorBtn} ${classes.selectorBtnRight}`
                }
                onClick={() => {
                    if (isActive) {
                        handleCancelClick(filter, setActive);
                    } else {
                        handleSelectClick(filter, setActive);
                    }
                }}
            >
                {isActive ? (
                    <i className="fas fa-times" />
                ) : (
                    <i className="fas fa-chevron-down" />
                )}
            </Button>
        </div>
    );

    return (
        <div className={classes.selectorContainer}>
            {renderButton(
                filterDate ? renderDateTitle(filterDate) : 'Date',
                'filter date',
                dateButtonActive,
                setDateButtonActive
            )}
            {renderButton(
                filterType ? renderTypeTitle(filterType) : 'Type',
                'filter type',
                typeButtonActive,
                setTypeButtonActive
            )}
        </div>
    );
};

export default Selectors;
