import { PexHistory } from 'types';
import { formatDate } from '../../utils';

type HistoryItemProps = {
    classes: {
        [key: string]: string;
    };
    item: PexHistory;
};

const HistoryItem: React.FC<HistoryItemProps> = ({ classes, item }) => {
    // const { AcctId, amounts, description, MerchantName, MerchantNameNormalized, redemption_type, special_type, TransactionAmount, type} = item;
    const isAssign = item?.TransactionType === 'assign';
    const isReplace = item?.TransactionType === 'replace';
    const isDonation = item.type === 'donation' && item.special_type === null;
    const isMilestone = item.special_type === 'milestone_bonus';
    const isRedemption =
        item.type === 'redemption' && item.redemption_type !== 'pex_transfer';
    const isSpending =
        (item.MerchantNameNormalized && item.MerchantNameNormalized !== null) ||
        (item.MerchantName && item.MerchantName.length > 0);
    const isPexTransfer = item.AcctId && !isSpending;

    const renderAmount = () => {
        if (isDonation || isMilestone) {
            return `+$${item.amounts!.given}`;
        } else if (isRedemption) {
            return `-$${item.amounts!.charged}`;
        } else if (isSpending) {
            return `-$${item.TransactionAmount.toString().split('-')[1]}`;
        } else if (isPexTransfer && item.TransactionAmount > 0) {
            return `+$${item.TransactionAmount}`;
        } else if (isPexTransfer && item.TransactionAmount < 0) {
            return `-$${item.TransactionAmount.toString().split('-')[1]}`;
        } else {
            return ``;
        }
    };

    const renderAmountClass = () => {
        if (
            isDonation ||
            isMilestone ||
            (isPexTransfer && item.TransactionAmount > 0)
        ) {
            return classes.add;
        } else if (
            isRedemption ||
            isSpending ||
            (isPexTransfer && item.TransactionAmount < 0)
        ) {
            return classes.subtract;
        } else {
            return classes.subtract;
        }
    };

    const renderDescription = () => {
        if (isDonation) {
            return `Donation from supporting samaritan`;
        } else if (isMilestone) {
            return `Bonus from Action Step`;
        } else if (isRedemption && item.description) {
            return `Purchase Request: ${item.description.split(' ')[0]}`;
        } else if (isRedemption && !item.description) {
            return `Purchase Request: -`;
        } else if (isSpending) {
            return `${item.MerchantNameNormalized || item.MerchantName}`;
        } else if (isAssign || isReplace) {
            return item.Description;
        } else if (isPexTransfer) {
            return `Funds Transferred`;
        } else {
            return `Transfer into Debit Card`;
            // return `Debit Transfer: Card ${pex.card_last4}`;
        }
    };

    const renderIcon = () => {
        if (
            isDonation ||
            isMilestone ||
            (isPexTransfer && item.TransactionAmount > 0)
        ) {
            return `plus`;
        } else if (
            isRedemption ||
            isSpending ||
            (isPexTransfer && item.TransactionAmount < 0)
        ) {
            return `minus`;
        } else {
            return `credit-card `;
        }
    };

    const renderIconClass = () => {
        if (
            isDonation ||
            isMilestone ||
            (isPexTransfer && item.TransactionAmount > 0)
        ) {
            return classes.plusIcon;
        } else if (
            isRedemption ||
            isSpending ||
            (isPexTransfer && item.TransactionAmount < 0)
        ) {
            return classes.minusIcon;
        } else {
            return classes.transferIcon;
        }
    };

    return (
        <div className={classes.itemBody}>
            <div className={classes.description}>
                <i
                    className={`${
                        classes.icon
                    } ${renderIconClass()} fal fa-${renderIcon()}`}
                />
                <div className={classes.descriptionBody}>
                    <span>{renderDescription()}</span>
                    <span className={classes.date}>
                        {item.date
                            ? formatDate(item.date)
                            : formatDate(item.TransactionTime)}
                    </span>
                </div>
            </div>
            <span className={`${classes.amount} ${renderAmountClass()}`}>
                {renderAmount()}
            </span>
        </div>
    );
};

export default HistoryItem;
