import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import EditIcon from './EditIcon';

import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    editNameContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        fontSize: '42px',
        fontWeight: '500',
        lineHeight: '57px',
        letterSpacing: '2.79px',
        position: 'relative',
        paddingRight: '10px'
    },
    nameInput: {},
    name: {
        width: '100%',
        height: 'auto',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: '#1F2152'
    }
}));

const EditName = ({
    name,
    editName,
    setEditName,
    newName,
    setNewName,
    handleSave
}) => {
    const classes = useStyles();
    const inputRef = useRef(null);

    useEffect(() => {
        if (editName && inputRef.current) {
            const input = inputRef.current.querySelector('input');

            input.scrollIntoView({ behavior: 'smooth' });
            input.focus();
        }
    }, [editName]);

    return (
        <div className={classes.editNameContainer}>
            <div className={classes.nameContainer}>
                {!editName ? (
                    <div className={classes.name}>
                        {newName ? newName : name}
                    </div>
                ) : (
                    <TextField
                        ref={inputRef}
                        className={classes.nameInput}
                        name="name"
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                color: '#1F2152',
                                fontFamily: 'Manrope',
                                padding: '0 !important',
                                border: 'none',
                                fontSize: '42px',
                                fontWeight: '500',
                                lineHeight: '57px',
                                letterSpacing: '2.79px',
                                background: 'transparent',
                                resize: 'none',
                                height: 'auto',
                                wordWrap: 'break-word',
                                '&:focus': {
                                    outline: 'none !important'
                                }
                            }
                        }}
                        variant="standard"
                    />
                )}
                <EditIcon
                    edit={editName}
                    setEdit={setEditName}
                    handleSave={handleSave}
                />
            </div>
        </div>
    );
};

EditName.propTypes = {
    name: PropTypes.string,
    editName: PropTypes.bool,
    setEditName: PropTypes.func,
    newName: PropTypes.string,
    setNewName: PropTypes.func,
    handleSave: PropTypes.func
};

export default EditName;
