import LoadingCircle from 'components/_shared/LoadingCircle';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        loadingOverlay: {
            position: 'absolute',
            left: '0',
            top: '48px',
            height: 'calc(100% - 48px)',
            width: 'calc(100%)',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '16px',
            // borderRadius: '16px',
            zIndex: 2
        },
        statusText: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            color: '#1B9757',
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'center',
            transform: 'translate(-50%, -50%)',
            '& i': {
                fontSize: '40px'
            }
        }
    })
);

type LoadingOverlayProps = {
    loading: boolean;
    managerName: string;
    organizationName: string;
    referralType: string;
    success: boolean;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    loading,
    managerName,
    organizationName,
    referralType,
    success
}) => {
    const classes = useStyles();

    const renderStatusText = () => {
        if (success && referralType === 'org') {
            return `Referrals sent to ${organizationName}`;
        } else if (success && referralType === 'manager') {
            return `Referrals sent to ${managerName}`;
        } else if (!success) {
            return `Error sending referrals`;
        } else {
            return `Sending referrals`;
        }
    };

    return (
        <div className={classes.loadingOverlay}>
            {loading ? (
                <LoadingCircle />
            ) : (
                <span className={classes.statusText}>
                    <i className={`fas fa-check-circle`} />
                    {renderStatusText()}
                </span>
            )}
        </div>
    );
};

export default LoadingOverlay;
