import SamaritanFetch from './httpClient';

export const fetchDebitTypes = () =>
    SamaritanFetch.v3('/remote_copies/debit_card_options', {
        headers: {
            Accept: 'application/json'
        }
    });
    
export const assignGiveCard = (memberID: number, last4: number) => {
    const body = {
        last4
    };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/assign`,
        {
            body: JSON.stringify(body)
        }
    );
};

export const fetchGiveCardHistory = (memberID: number, full: boolean) => {
    const query = {} as {
        page?: number;
        page_size?: number;
    };

    if (!full) {
        query.page = 1;
        query.page_size = 3;
    }

    return SamaritanFetch.v3(
        `/samaritan_members/${memberID}/givecard/transaction_history`,
        {
            query
        }
    );
};

export const transferGiveCardFunds = (memberID: number, amount: number) => {
    const body = {
        amount
    };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/fund`,
        {
            body: JSON.stringify(body)
        }
    );
};

export const updateGiveCardPin = (memberID: number, pin: number) => {
    const body = {
        new_pin: pin
    };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/update_pin`,
        {
            body: JSON.stringify(body)
        }
    );
};
