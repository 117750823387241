const calculateDate = (days, display) => {
    const today = new Date();
    const lastDate = new Date(today);
    lastDate.setDate(today.getDate() - days);

    const formattedMonth = lastDate.getMonth() + 1;

    if (display) {
        return `${formatDate(lastDate)} - ${formatDate(today)}`;
    } else {
        return `${lastDate.getFullYear()}-${formattedMonth}-${lastDate.getDate()}`;
    }
};

const calculateDateFromDays = (days) => {
    const today = new Date();
    const lastDate = new Date(today);
    lastDate.setDate(today.getDate() - days);

    const formattedMonth = lastDate.getMonth() + 1;

    return `${lastDate.getFullYear()}-${formattedMonth}-${lastDate.getDate()}`;
};

const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
};

const renderDateTitle = (filterDate) => {
    switch (filterDate) {
        case calculateDateFromDays(0):
            return 'Date';
        case calculateDateFromDays(7):
            return 'Last Week';
        case calculateDateFromDays(30):
            return 'Last Month';
        case calculateDateFromDays(180):
            return 'Last 6 Months';
        case calculateDateFromDays(null):
            return 'Test';
        default:
            return 'Custom Range';
    }
};

const renderTypeTitle = (filterType) => {
    switch (filterType) {
        case 'action_step':
            return 'Action Steps';
        case 'all':
            return 'Type';
        case 'fund':
            return 'Member Balance';
        case 'need':
            return 'Needs';
        case 'pex_card':
            return 'Reloadable Debit Card Assignments';
        case 'status_capture':
            return `Member's Status`;
        default:
            return '';
    }
};

export {
    calculateDate,
    calculateDateFromDays,
    formatDate,
    renderDateTitle,
    renderTypeTitle
};
