import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { fetchRedemptionDetails } from "../../../../../../api/redemption";
import { numToDollars } from "../../../../../../util";

import CTAButton2 from "../../../../../_shared/buttons/CTAButton2";
import Input from '../../../../../_shared/forms/Input.tsx';

import Form from "./Form";

import classes from "./index.module.scss";
import LoadingCircle from "../../../../../_shared/LoadingCircle";

export default function FormContainer({ templateId, onSubmit, initialState }) {
  const [formTemplate, setFormTemplate] = useState({});
  const [amount, setAmount] = useState(initialState.amount || 0);

  // separate request type and all the other answers
  const { requestType: initialRequestType, ...restInitialState } =
    initialState || {};
  // set request type if one exists already
  const [formType, setFormType] = useState(initialRequestType || null);

  // get details for templateId
  useEffect(() => {
    fetchRedemptionDetails(templateId)
      .then((res) => res.json())
      .then((json) => setFormTemplate(json.template));
  }, [templateId]);

  const onSubmitCallback = (answers) => {
    // gather data
    const data = {
      ...answers,
      amount,
      category: formTemplate.category,
      title: formTemplate.title,
      requestType: formType,
    };

    // callback
    onSubmit(data);
  };

  if (!Object.keys(formTemplate).length) return <LoadingCircle />;

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div className={classes.titles}>
          <div className={classes.category}>{formTemplate.category}</div>
          <div className={classes.title}>{formTemplate.title}</div>
        </div>
        <div className={classes.img}>
          <img src={formTemplate.thumbnail_url} alt={formTemplate.title} />
        </div>
      </div>
      <div style={{ marginBottom: 30 }}>
        <div>Charge Amount:</div>
        {/* use form element to enable `autoFocus`, noop on submit */}
        <form onSubmit={() => {}}>
          $
          <Input
            autoFocus
            className={classes.amountInput}
            type="number"
            placeholder="0.00"
            aria-label="charge amount"
            min={formTemplate.min_amount ? formTemplate.min_amount : 0.01}
            step={0.01}
            value={amount || ""}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            onBlur={(e) => e.target.reportValidity()}
            required
          />
          {formTemplate.min_amount && (
            <span>(min: {numToDollars(formTemplate.min_amount)})</span>
          )}
        </form>
      </div>
      <div>
        <div className={classes.typeSelectorLabel}>
          Select Request Form Type
        </div>
        <div className={classes.typeSelectorButtons}>
          <CTAButton2
            unselected={formType && formType !== "Fulfillment"}
            onClick={() => {
              setFormType("Fulfillment");
            }}
          >
            Fulfillment
          </CTAButton2>
          <CTAButton2
            unselected={formType && formType !== "Reimbursement"}
            onClick={() => {
              setFormType("Reimbursement");
            }}
          >
            Reimbursement
          </CTAButton2>
        </div>
        {formType === "Fulfillment" && (
          <Form
            initialState={restInitialState}
            disclaimer={formTemplate.disclaimer}
            instructions={formTemplate.instructions}
            items={formTemplate.items}
            onSubmit={onSubmitCallback}
          />
        )}
        {formType === "Reimbursement" && (
          <Form
            initialState={restInitialState}
            title="Reimbursement Information:"
            disclaimer={reimbursementFormDetails.disclaimer}
            instructions={reimbursementFormDetails.instructions}
            items={reimbursementFormDetails.items}
            onSubmit={onSubmitCallback}
          />
        )}
      </div>
    </div>
  );
}

FormContainer.propTypes = {
  templateId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialState: PropTypes.object,
};

FormContainer.defaultProps = {
  initialState: {},
};

/**
 * Reimbursement form is just a special fulfillment form.
 */
const reimbursementFormDetails = {
  disclaimer:
    "Reimbursement requests submitted through this form will be processed at the beginning of each month.  A check for the amount charged from the card will be mailed to the the provided address below.",
  instructions:
    "Please e-mail support@samaritan.city with a copy of the receipt received when you purchased this item for your client. Reimbursement requests will only be processed when the receipt is received.",
  items: [
    { title: "Payable To", is_optional: false, options: [] },
    { title: "Mailing Address", is_optional: false, options: [] },
  ],
};
