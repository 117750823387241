import CONSTANTS from '../constants/constants';

import {
    DebitCard,
    DebitCardHistory,
    DebitCardOptions,
    PexHistory
} from 'types';

import { updateObject } from '../../util';

// TODO: Add types for history when implemented
const initialState = {
    debitCardOptions: null,
    debitHistory: [],
    debitInfo: {} as DebitCard,
    fullDebitHistory: [],
    isFetchingDebitCardOptions: false,
    isFetchingDebitHistory: false,
    isFetchingDebitInfo: false
} as {
    debitCardOptions: DebitCardOptions[] | null;
    debitHistory: PexHistory[] | DebitCardHistory[];
    debitInfo: DebitCard;
    fullDebitHistory: PexHistory[] | DebitCardHistory[];
    isFetchingDebitCardOptions: boolean;
    isFetchingDebitHistory: boolean;
    isFetchingDebitInfo: boolean;
};

export default function debitCard_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.FETCH_DEBIT_CARD_HISTORY:
            return {
                ...state,
                isFetchingDebitHistory: true
            };
        case CONSTANTS.GET_DEBIT_CARD_INFO:
            return {
                ...state,
                isFetchingDebitInfo: true
            };
        case CONSTANTS.GET_DEBIT_CARD_OPTIONS:
            return {
                ...state,
                isFetchingDebitCardOptions: true
            };
        case CONSTANTS.SET_DEBIT_CARD_BALANCE:
            return {
                ...state,
                debitInfo: action.payload
            };
        case CONSTANTS.SET_DEBIT_CARD_FULL_HISTORY:
            return {
                ...state,
                fullDebitHistory: action.payload,
                isFetchingDebitHistory: false
            };
        case CONSTANTS.SET_DEBIT_CARD_HISTORY:
            return {
                ...state,
                debitHistory: action.payload,
                isFetchingDebitHistory: false
            };
        case CONSTANTS.SET_DEBIT_CARD_INFO:
            if (action.payload !== state.debitInfo) {
                return {
                    ...state,
                    debitInfo: action.payload,
                    isFetchingDebitInfo: false
                };
            } else {
                return state;
            }
        case CONSTANTS.SET_DEBIT_CARD_OPTIONS:
            return {
                ...state,
                isFetchingDebitCardOptions: false,
                debitCardOptions: action.payload
            };
        case CONSTANTS.UPDATE_DEBIT_CARD_BALANCE:
            return {
                ...state,
                debitInfo: updateObject(
                    state.debitInfo,
                    'available_balance',
                    action.payload.balance
                )
            };
        default:
            return state;
    }
}
