// TODO: Fix ts-ignore for action steps
// @ts-ignore
import ActionSteps from './ActionSteps';
import SDOHStatus from './SDOHStatus';

import {
    Homeless,
    TouchpointAnswers,
    TouchpointQuestions
} from '../../../../types';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    caseManagementContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '80px',
        padding: '30px 12% 60px 12%'
    }
}));

type Props = {
    addStatus: boolean;
    assignedBonusAmount: number;
    exited: boolean;
    fetchTouchpointAnswersById: (id: number) => void;
    goalActions: object[];
    holder: Homeless;
    isEditingGoalAction: boolean;
    isFetchingTouchpointAnswersById: boolean;
    isSubmittingTouchpointAnswers: boolean;
    maxBonusAmount: number;
    newHomelessGoalActions: any;
    remainingBonusAmount: number;
    setAddStatus: (value: boolean) => void;
    touchpointAnswers: TouchpointAnswers[];
    touchpointAnswersById: object;
    touchpointQuestions: TouchpointQuestions[];
    touchpointQuestionQOLs: any;
    updateProfileCompletedItems: (profileCompletedItems: string) => void;
    user: {
        id: number;
    };
};

const CaseManagement: React.FC<Props> = ({ goalActions, ...props }) => {
    const classes = useStyles();

    const statusProps = {
        goalActions,
        ...props
    };

    return (
        <div className={classes.caseManagementContainer}>
            {!props.exited && <ActionSteps {...statusProps} />}
            <SDOHStatus {...statusProps} />
        </div>
    );
};

export default CaseManagement;
