import Card from '../../CaseManagement/components/Card';
import EditName from './EditName';
import EditProfilePicture from './EditProfilePicture';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    }
}));

const BasicInfo = ({ ...props }) => {
    const classes = useStyles();

    const {
        name,
        editName,
        setEditName,
        newName,
        setNewName,
        handleSave,
        photo,
        editProfilePicture,
        setEditProfilePicture,
        newProfilePicture,
        setNewProfilePicture
    } = props;

    const editNameProps = {
        name,
        editName,
        setEditName,
        newName,
        setNewName,
        handleSave
    };

    const editProfilePictureProps = {
        photo,
        editProfilePicture,
        setEditProfilePicture,
        newProfilePicture,
        setNewProfilePicture,
        handleSave
    };

    return (
        <div className={classes.infoContainer}>
            <Card info profile />
            <EditName {...editNameProps} />
            <EditProfilePicture {...editProfilePictureProps} />
        </div>
    );
};

export default BasicInfo;
