export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

export const GET_TOUCHPOINT_QUESTIONS = 'GET_TOUCHPOINT_QUESTIONS';
export const GET_TOUCHPOINT_QUESTIONS_SUCCESS =
    'GET_TOUCHPOINT_QUESTIONS_SUCCESS';
export const GET_TOUCHPOINT_QUESTIONS_FAIL = 'GET_TOUCHPOINT_QUESTIONS_FAIL';
export const SET_TOUCHPOINT_QUESTIONS = 'SET_TOUCHPOINT_QUESTIONS';
export const SET_TOUCHPOINT_QUESTIONS_ERROR = 'SET_TOUCHPOINT_QUESTIONS_ERROR';
export const SET_TOUCHPOINT_QUESTION_IDS = 'SET_TOUCHPOINT_QUESTION_IDS';

export const GET_SUPPLEMENTAL_QUESTIONS = 'GET_SUPPLEMENTAL_QUESTIONS';
export const SET_SUPPLEMENTAL_QUESTIONS = 'SET_SUPPLEMENTAL_QUESTIONS';
export const SET_SUPPLEMENTAL_QUESTION_IDS = 'SET_SUPPLEMENTAL_QUESTION_IDS';
export const GET_SUPPLEMENTAL_QUESTION_ANSWERS =
    'GET_SUPPLEMENTAL_QUESTION_ANSWERS';
export const SET_SUPPLEMENTAL_QUESTION_ANSWERS =
    'SET_SUPPLEMENTAL_QUESTION_ANSWERS';
export const UPDATE_SUPPLEMENTAL_QUESTION_ANSWERS =
    'UPDATE_SUPPLEMENTAL_QUESTION_ANSWERS';

export const GET_TOUCHPOINT_ANSWERS = 'GET_TOUCHPOINT_ANSWERS';
export const GET_TOUCHPOINT_ANSWERS_BY_ID = 'GET_TOUCHPOINT_ANSWERS_BY_ID';
export const GET_TOUCHPOINT_ANSWERS_FAIL = 'GET_TOUCHPOINT_ANSWERS_FAIL';
export const SET_TOUCHPOINT_ANSWERS = 'SET_TOUCHPOINT_ANSWERS';
export const SET_TOUCHPOINT_ANSWERS_BY_ID = 'SET_TOUCHPOINT_ANSWERS_BY_ID';
export const UPDATE_TOUCHPOINT_ANSWERS = 'UPDATE_TOUCHPOINT_ANSWERS';
export const RESET_TOUCHPOINT_ANSWERS = 'RESET_TOUCHPOINT_ANSWERS';
export const SET_TOUCHPOINT_STATUS = 'SET_TOUCHPOINT_STATUS';
export const SUBMIT_TOUCHPOINT_ANSWERS = 'SUBMIT_TOUCHPOINT_ANSWERS';
export const INCREMENT_IMPROVED_STATUS_COUNT =
    'INCREMENT_IMPROVED_STATUS_COUNT';
export const DECREMENT_IMPROVED_STATUS_COUNT =
    'DECREMENT_IMPROVED_STATUS_COUNT';
export const SET_IMPROVED_TOUCHPOINT_STATUS = 'SET_IMPROVED_TOUCHPOINT_STATUS';
export const SET_REDUCED_TOUCHPOINT_STATUS = 'SET_REDUCED_TOUCHPOINT_STATUS';

export const GET_CASELOADS = 'GET_CASELOADS';
export const SET_CASELOADS = 'SET_CASELOADS';
export const CREATE_CASELOAD = 'CREATE_CASELOAD';
export const SET_CASELOADS_ERROR = 'SET_CASELOADS_ERROR';
export const GET_HOMELESSES = 'GET_HOMELESSES';
export const SET_HOMELESSES = 'SET_HOMELESSES';
export const SET_HOMELESSES_ERROR = 'SET_HOMELESSES_ERROR';

export const ADD_MEMBER_TO_MY_MEMBERS = 'ADD_MEMBER_TO_MY_MEMBERS';
export const REMOVE_MEMBER_FROM_MY_MEMBERS = 'REMOVE_MEMBER_FROM_MY_MEMBERS';

export const GET_HOMELESS_INFO = 'GET_HOMELESS_INFO';
export const SET_HOMELESS_INFO_ERROR = 'SET_HOMELESS_INFO_ERROR';
export const SET_HOMELESS_INFO = 'SET_HOMELESS_INFO';
export const RESET_HOMELESS_INFO = 'RESET_HOMELESS_INFO';
export const UPDATE_HOMELESS_BALANCE = 'UPDATE_HOMELESS_BALANCE';

export const GET_HOMELESS_PEX_INFO = 'GET_HOMELESS_PEX_INFO';
export const SET_HOMELESS_PEX_INFO = 'SET_HOMELESS_PEX_INFO';
export const SET_HOMELESS_PEX_BALANCE = 'SET_HOMELESS_PEX_BALANCE';
export const UPDATE_HOMELESS_PEX_BALANCE = 'UPDATE_HOMELESS_PEX_BALANCE';

export const GET_HOMELESS_TOUCHPOINTS = 'GET_HOMELESS_TOUCHPOINTS';
export const CANCEL_GET_TOUCHPOINT_QUESTIONS =
    'CANCEL_GET_TOUCHPOINT_QUESTIONS';
export const SET_HOMELESS_TOUCHPOINTS = 'SET_HOMELESS_TOUCHPOINTS';

export const GET_HOMELESS_TIMELINE = 'GET_HOMELESS_TIMELINE';
export const SET_HOMELESS_TIMELINE = 'SET_HOMELESS_TIMELINE';

export const GET_HOMELESS_GOALS = 'GET_HOMELESS_GOALS';
export const SET_HOMELESS_GOALS = 'SET_HOMELESS_GOALS';

export const POSTING_NEW_HOMELESS_GOAL = 'POSTING_NEW_HOMELESS_GOAL';
export const POSTED_NEW_HOMELESS_GOAL = 'POSTED_NEW_HOMELESS_GOAL';
export const FAILED_NEW_HOMELESS_GOAL = 'FAILED_NEW_HOMELESS_GOAL';

export const GET_HOMELESS_GOAL_ACTIONS = 'GET_HOMELESS_GOAL_ACTIONS';
export const SET_HOMELESS_GOAL_ACTIONS = 'SET_HOMELESS_GOAL_ACTIONS';
export const SET_HOMELESS_GOAL_ACTION_REQUEST_STATE =
    'SET_HOMELESS_GOAL_REQUEST_STATE';
export const UPDATE_HOMELESS_GOAL_ACTION_STATUS =
    'UPDATE_HOMELESS_GOAL_ACTION_STATUS';
export const UPDATE_HOMELESS_GOAL_ACTION_MATCH_AMOUNT =
    'UPDATE_HOMELESS_GOAL_ACTION_MATCH_AMOUNT';
export const UPDATE_HOMELESS_GOAL_ACTION_PENDING_PAYMENTS =
    'UPDATE_HOMELESS_GOAL_ACTION_PENDING_PAYMENTS';
export const REMOVE_HOMELESS_GOAL_ACTION = 'REMOVE_HOMELESS_GOAL_ACTION';
export const SHOW_GOAL_ACTION_COMPLETE_POPUP =
    'SHOW_GOAL_ACTION_COMPLETE_POPUP';
export const SET_GOAL_ACTION_COMPLETE_POPUP = 'SET_GOAL_ACTION_COMPLETE_POPUP';

export const POSTING_NEW_HOMELESS_GOAL_ACTION =
    'POSTING_NEW_HOMELESS_GOAL_ACTION';
export const POSTED_NEW_HOMELESS_GOAL_ACTION =
    'POSTED_NEW_HOMELESS_GOAL_ACTION';
export const FAILED_NEW_HOMELESS_GOAL_ACTION =
    'FAILED_NEW_HOMELESS_GOAL_ACTION';
export const EDITING_HOMELESS_GOAL_ACTION = 'EDITING_HOMELESS_GOAL_ACTION';
export const EDITED_HOMELESS_GOAL_ACTION = 'EDITED_HOMELESS_GOAL_ACTION';
export const FAILED_EDIT_HOMELESS_GOAL_ACTION =
    'FAILED_EDIT_HOMELESS_GOAL_ACTION';

export const MARK_SDOH_COMPLETED = 'MARK_SDOH_COMPLETED';

export const GET_HOMELESS_NEEDS = 'GET_HOMELESS_NEEDS';
export const SET_HOMELESS_NEEDS = 'SET_HOMELESS_NEEDS';
export const CHANGE_LOCAL_NEED_STATUS = 'CHANGE_LOCAL_NEED_STATUS';
export const REMOVE_HOMELESS_NEED = 'REMOVE_HOMELESS_NEED';

export const POSTING_NEW_HOMELESS_NEED = 'POST_NEW_HOMELESS_NEED';
export const POSTED_NEW_HOMELESS_NEED = 'POSTED_NEW_HOMELESS_NEED';
export const EDITING_HOMELESS_NEED = 'EDITING_HOMELESS_NEED';
export const EDITED_HOMELESS_NEED = 'EDITED_HOMELESS_NEED';
export const FAILED_NEW_HOMELESS_NEED = 'FAILED_NEW_HOMELESS_NEED';
export const FAILED_EDIT_HOMELESS_NEED = 'FAILED_EDIT_HOMELESS_NEED';

export const GET_NEWSFEED = 'GET_NEWSFEED';
export const SET_NEWSFEED = 'SET_NEWSFEED';

export const GET_MONTHLY_SUMMARY = 'GET_MONTHLY_SUMMARY';
export const SET_MONTHLY_SUMMARY = 'SET_MONTHLY_SUMMARY';

export const UNAUTHORIZED_USER = 'UNAUTHORIZED_USER';
export const RESET_UNAUTHORIZED = 'RESET_UNAUTHORIZED';
export const GET_API_ERROR = 'GET_API_ERROR';
export const RESET_API_ERROR = 'RESET_API_ERROR';
export const SET_INTAKE_ERROR = 'SET_INTAKE_ERROR';
export const SET_INTAKE_TOUCHPOINT_ERROR = 'SET_INTAKE_TOUCHPOINT_ERROR';

export const GET_BEACON_RENEW = 'GET_BEACON_RENEW';
export const SET_BEACON_RENEW = 'SET_BEACON_RENEW';

export const RESET = 'RESET';

export const SET_LOCAL_TOUCHPOINT = 'SET_LOCAL_TOUCHPOINT';
export const REMOVE_LOCAL_TOUCHPOINT = 'REMOVE_LOCAL_TOUCHPOINT';
export const SET_LOCAL_TOUCHPOINT_DESCRIPTION =
    'SET_LOCAL_TOUCHPOINT_DESCRIPTION';
export const SET_LOCAL_COMPLETION_ON_GOAL_ACTION =
    'SET_LOCAL_COMPLETION_ON_GOAL_ACTION';
export const SET_LOCAL_COMPLETION_ON_NEED = 'SET_LOCAL_COMPLETION_ON_NEED';
export const MEMO_GOAL_ACTION_STATUS_UPDATE = 'MEMO_GOAL_ACTION_STATUS_UPDATE';
export const MEMO_NEED_STATUS_UPDATE = 'MEMO_NEED_STATUS_UPDATE';
export const SET_LOCAL_TOUCHPOINT_GOAL_ACTIONS =
    'SET_LOCAL_TOUCHPOINT_GOAL_ACTIONS';
export const SET_LOCAL_TOUCHPOINT_NEEDS = 'SET_LOCAL_TOUCHPOINT_NEEDS';
export const SET_LOCAL_TOUCHPOINT_ANSWER = 'SET_LOCAL_TOUCHPOINT_ANSWER';
export const SET_LOCAL_TOUCHPOINT_ANSWER_CONTEXT =
    'SET_LOCAL_TOUCHPOINT_ANSWER_CONTEXT';
export const SET_LOCAL_PREVIOUS_TOUCHPOINTS = 'SET_LOCAL_PREVIOUS_TOUCHPOINTS';
export const SET_REWARDABILITY_ON_GOAL_ACTION =
    'SET_REWARDABILITY_ON_GOAL_ACTION';

export const GET_DETERMINANT_RANKS = 'GET_DETERMINANT_RANKS';
export const SET_DETERMINANT_RANKS = 'SET_DETERMINANT_RANKS';

export const GET_SKILLS = 'GET_SKILLS';
export const CANCEL_GET_SKILLS = 'CANCEL_GET_SKILLS';
export const SET_SKILLS = 'SET_SKILLS';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';

export const SET_LAYOUT_TITLES = 'SET_LAYOUT_TITLES';
export const SET_BACK_BUTTON_URL = 'SET_BACK_BUTTON_URL';

export const GET_ORGANIZATION_TOUCHPOINTS = 'GET_ORGANIZATION_TOUCHPOINTS';
export const SET_ORGANIZATION_TOUCHPOINTS = 'SET_ORGANIZATION_TOUCHPOINTS';

export const GET_ORGANIZATION_PAYMENTS = 'GET_ORGANIZATION_PAYMENTS';
export const SET_ORGANIZATION_PAYMENTS = 'SET_ORGANIZATION_PAYMENTS';

export const GET_MEMBER_PAYMENTS = 'GET_MEMBER_PAYMENTS';
export const SET_MEMBER_PAYMENTS = 'SET_MEMBER_PAYMENTS';
export const SET_FULL_MEMBER_PAYMENTS = 'SET_FULL_MEMBER_PAYMENTS';
export const RESET_FULL_MEMBER_PAYMENTS = 'RESET_FULL_MEMBER_PAYMENTS';

export const GET_ORGANIZATION_ENCOURAGEMENTS =
    'GET_ORGANIZATION_ENCOURAGEMENTS';
export const SET_ORGANIZATION_ENCOURAGEMENTS =
    'SET_ORGANIZATION_ENCOURAGEMENTS';

export const RESET_INTAKE = 'RESET_INTAKE';
export const DECREMENT_INTAKE_STEP = 'DECREMENT_INTAKE_STEP';
export const INCREMENT_INTAKE_STEP = 'INCREMENT_INTAKE_STEP';
export const SET_INTAKE_DEPLOYMENT_ID = 'SET_INTAKE_DEPLOYMENT_ID';
export const SET_INTAKE_PROFILE_PICTURE = 'SET_INTAKE_PROFILE_PICTURE';
export const SET_INTAKE_PICTURE_TOGGLE = 'SET_INTAKE_PICTURE_TOGGLE';
export const SET_INTAKE_PROFILE_NAME = 'SET_INTAKE_PROFILE_NAME';
export const SET_INTAKE_GOALS = 'SET_INTAKE_GOALS';
export const SET_INTAKE_NEEDS = 'SET_INTAKE_NEEDS';
export const SET_INTAKE_LIFESTORY = 'SET_INTAKE_LIFESTORY';
export const SET_INTAKE_SKILLS = 'SET_INTAKE_SKILLS';
export const REMOVE_INTAKE_SKILL = 'REMOVE_INTAKE_SKILL';
export const SET_INTAKE_BIRTH_DAY = 'SET_INTAKE_BIRTH_DAY';
export const SET_INTAKE_BIRTH_MONTH = 'SET_INTAKE_BIRTH_MONTH';
export const SET_INTAKE_EMAIL = 'SET_INTAKE_EMAIL';
export const SET_INTAKE_PHONE_NUMBER = 'SET_INTAKE_PHONE_NUMBER';
export const SET_INTAKE_QUESTION_ANSWER = 'SET_INTAKE_QUESTION_ANSWER';
export const TOGGLE_INTAKE_AGREED = 'TOGGLE_INTAKE_AGREED';
export const GET_INTAKE_SUBMIT = 'GET_INTAKE_SUBMIT';
export const SET_INTAKE_SUBMIT = 'SET_INTAKE_SUBMIT';
export const SET_INTAKE_TOUCHPOINT_ANSWER = 'SET_INTAKE_TOUCHPOINT_ANSWER';
export const GET_INTAKE_TOUCHPOINT_SUBMIT = 'GET_INTAKE_TOUCHPOINT_SUBMIT';
export const SET_INTAKE_TOUCHPOINT_SUBMIT = 'SET_INTAKE_TOUCHPOINT_SUBMIT';
export const SET_INTAKE_BEACON_ID = 'SET_INTAKE_BEACON_ID';
export const SET_INTAKE_ICEBREAKER = 'SET_INTAKE_ICEBREAKER';
export const SET_INTAKE_QR = 'SET_INTAKE_QR';
export const SET_INTAKE_RELEVANT_ID = 'SET_INTAKE_RELEVANT_ID';
export const SET_INTAKE_INTERVIEWER = 'SET_INTAKE_INTERVIEWER';
export const SET_INTAKE_INTERVIEWER_ID = 'SET_INTAKE_INTERVIEWER_ID';
export const SET_INTAKE_PRIVATE_FIRST_NAME = 'SET_INTAKE_PRIVATE_FIRST_NAME';
export const SET_INTAKE_PRIVATE_MIDDLE_NAME = 'SET_INTAKE_PRIVATE_MIDDLE_NAME';
export const SET_INTAKE_PRIVATE_LAST_NAME = 'SET_INTAKE_PRIVATE_LAST_NAME';
export const SET_INTAKE_PRIVATE_FULL_NAME = 'SET_INTAKE_PRIVATE_FULL_NAME';
export const SET_INTAKE_ORG_ID = 'SET_INTAKE_ORG_ID';
export const SET_INTAKE_PROGRESS_WIDTH = 'SET_INTAKE_PROGRESS_WIDTH';

export const GETTING_REDEMPTION_LIST = 'GETTING_REDEMPTION_LIST';
export const GOT_REDEMPTION_LIST = 'GOT_REDEMPTION_LIST';
export const FAILED_GETTING_REDEMPTION_LIST = 'FAILED_GETTING_REDEMPTION_LIST';

export const SET_UNENROLLMENT_STATE = 'SET_UNENROLLMENT_STATE';
export const RESET_UNENROLLMENT_STATE = 'RESET_UNENROLLMENT_STATE';
export const SET_UNENROLLMENT_SDOH_STATUS = 'SET_UNENROLLMENT_SDOH_STATUS';
export const SET_UNENROLLMENT_REDEEM_STATUS = 'SET_UNENROLLMENT_REDEEM_STATUS';

export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS';
export const GET_CHAT_ROOMS_SUCCESS = 'GET_CHAT_ROOMS_SUCCESS';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const SET_ALL_CHAT_MESSAGES = 'SET_ALL_CHAT_MESSAGES';
export const SEND_MESSAGES = 'SEND_MESSAGES';
export const SEND_MESSAGES_SUCCESS = 'SEND_MESSAGES_SUCCESS';
export const SET_CHAT_ROOM_RECIPIENTS = 'SET_CHAT_ROOM_RECIPIENTS';
export const RESEND_MESSAGE = 'RESEND_MESSAGE';
export const RESEND_MESSAGE_SUCCESS = 'RESEND_MESSAGE_SUCCESS';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const SET_MESSAGE_HOLDER_ID = 'SET_MESSAGE_HOLDER_ID';
export const RESET_MESSAGE_INFO = 'RESET_MESSAGE_INFO';

export const GET_QUALITY_OF_LIFE_MEASURES = 'GET_QUALITY_OF_LIFE_MEASURES';
export const SET_QUALITY_OF_LIFE_MEASURES = 'SET_QUALITY_OF_LIFE_MEASURES';
export const SET_TOUCHPOINT_QUESTION_QUALITY_OF_LIFE_MEASURES =
    'SET_TOUCHPOINT_QUESTION_QUALITY_OF_LIFE_MEASURES';

export const SUBMIT_STATUS_CAPTURE = 'SUBMIT_STATUS_CAPTURE';

export const SET_VIEW_GUIDE_MESSAGE = 'SET_VIEW_GUIDE_MESSAGE';

export const FETCH_PEX_HISTORY = 'FETCH_PEX_HISTORY';
export const SET_PEX_HISTORY = 'SET_PEX_HISTORY';
export const SET_FULL_PEX_HISTORY = 'SET_FULL_PEX_HISTORY';

export const FETCH_HOMELESS_LIFESTORY = 'FETCH_HOMELESS_LIFESTORY';
export const SET_HOMELESS_LIFESTORY = 'SET_HOMELESS_LIFESTORY';
export const UPDATE_HOMELESS_LIFESTORY = 'UPDATE_HOMELESS_LIFESTORY';
export const FETCH_MEMBER_HISTORY = 'FETCH_MEMBER_HISTORY';
export const SET_MEMBER_HISTORY = 'SET_MEMBER_HISTORY';
export const FETCH_HISTORY_FILTERS = 'FETCH_HISTORY_FILTERS';
export const SET_HISTORY_FILTERS = 'SET_HISTORY_FILTERS';

export const UPDATE_BONUS_AMOUNT = 'UPDATE_BONUS_AMOUNT';
export const SET_MAX_BONUS_AMOUNT = 'SET_MAX_BONUS_AMOUNT';

export const UPDATE_SHOW_GUIDE_SETTING = 'UPDATE_SHOW_GUIDE_SETTING';

// User / Member Languages
export const UPDATE_HOMELESSES_LANGUAGE_PREFERENCE =
    'UPDATE_HOMELESSES_LANGUAGE_PREFERENCE';
export const UPDATE_LANGUAGE_SETTING = 'UPDATE_LANGUAGE_SETTING';
export const SET_HOMELESSES_LANGUAGES = 'SET_HOMELESS_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';

export const INCREMENT_ENROLLED_MEMBERS = 'INCREMENT_ENROLLED_MEMBERS';

// Profile Completion
export const SET_PROFILE_COMPLETION = 'SET_PROFILE_COMPLETION';
export const UPDATE_PROFILE_COMPLETION = 'UPDATE_PROFILE_COMPLETION';
export const SET_PROFILE_COMPLETED_ITEMS = 'SET_PROFILE_COMPLETED_ITEMS';
export const UPDATE_PROFILE_COMPLETED_ITEMS = 'UPDATE_PROFILE_COMPLETED_ITEMS';
export const UPDATE_HOMELESS_INFO_FIELD = 'UPDATE_HOMELESS_INFO_FIELD';

// Multi-Org Deployment + Member Referral
export const GET_DEPLOYMENT = 'GET_DEPLOYMENT';
export const SET_DEPLOYMENT = 'SET_DEPLOYMENT';
export const GET_DEPLOYMENT_ORGS = 'GET_DEPLOYMENT_ORGS';
export const SET_DEPLOYMENT_ORGS = 'SET_DEPLOYMENTS_ORGS';
export const GET_DEPLOYMENT_USERS = 'GET_DEPLOYMENT_USERS';
export const SET_DEPLOYMENT_USERS = 'SET_DEPLOYMENT_USERS';
export const REFER_MEMBER = 'REFER_MEMBER';
export const REFER_MEMBER_FAIL = 'REFER_MEMBER_FAIL';
export const REFER_MEMBER_SUCCESS = 'REFER_MEMBER_SUCCESS';

// Catch Up Items
export const COMPLETE_CATCH_UP_ITEM = 'COMPLETE_CATCH_UP_ITEM';
export const FETCH_CATCH_UP_ITEMS = 'FETCH_CATCH_UP_ITEMS';
export const SET_CATCH_UP_ITEMS = 'SET_CATCH_UP_ITEMS';
export const SNOOZE_CATCH_UP_ITEM = 'SNOOZE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';

// Debit Card (PEX / GiveCard)
export const ASSIGN_DEBIT_CARD = 'ASSIGN_DEBIT_CARD';
export const FETCH_DEBIT_CARD_HISTORY = 'FETCH_DEBIT_CARD_HISTORY';
export const GET_DEBIT_CARD_INFO = 'GET_DEBIT_CARD_INFO';
export const GET_DEBIT_CARD_OPTIONS = 'GET_DEBIT_CARD_OPTIONS';
export const SET_DEBIT_CARD_BALANCE = 'SET_DEBIT_CARD_BALANCE';
export const SET_DEBIT_CARD_FULL_HISTORY = 'SET_DEBIT_CARD_FULL_HISTORY';
export const SET_DEBIT_CARD_HISTORY = 'SET_DEBIT_CARD_HISTORY';
export const SET_DEBIT_CARD_INFO = 'SET_DEBIT_CARD_INFO';
export const SET_DEBIT_CARD_OPTIONS = 'SET_DEBIT_CARD_OPTIONS';
export const UPDATE_DEBIT_CARD_BALANCE = 'UPDATE_DEBIT_CARD_BALANCE';
export const UPDATE_DEBIT_CARD_PIN = 'UPDATE_DEBIT_CARD_PIN';
export const UPDATE_HOMELESS_CARD_BALANCE = 'UPDATE_HOMELESS_CARD_BALANCE';

const constants = {
    GET_TOUCHPOINT_QUESTIONS,
    GET_TOUCHPOINT_QUESTIONS_SUCCESS,
    GET_TOUCHPOINT_QUESTIONS_FAIL,
    SET_TOUCHPOINT_QUESTIONS,
    SET_TOUCHPOINT_QUESTION_IDS,
    GET_TOUCHPOINT_ANSWERS,
    GET_TOUCHPOINT_ANSWERS_BY_ID,
    GET_TOUCHPOINT_ANSWERS_FAIL,
    SET_TOUCHPOINT_ANSWERS,
    SET_TOUCHPOINT_ANSWERS_BY_ID,
    UPDATE_TOUCHPOINT_ANSWERS,
    RESET_TOUCHPOINT_ANSWERS,
    GET_SUPPLEMENTAL_QUESTIONS,
    SET_SUPPLEMENTAL_QUESTIONS,
    SET_SUPPLEMENTAL_QUESTION_IDS,
    GET_SUPPLEMENTAL_QUESTION_ANSWERS,
    SET_SUPPLEMENTAL_QUESTION_ANSWERS,
    UPDATE_SUPPLEMENTAL_QUESTION_ANSWERS,
    INCREMENT_IMPROVED_STATUS_COUNT,
    DECREMENT_IMPROVED_STATUS_COUNT,
    SET_IMPROVED_TOUCHPOINT_STATUS,
    SET_REDUCED_TOUCHPOINT_STATUS,
    SET_TOUCHPOINT_STATUS,
    SUBMIT_TOUCHPOINT_ANSWERS,
    GET_CASELOADS,
    SET_CASELOADS,
    CREATE_CASELOAD,
    SET_CASELOADS_ERROR,
    GET_HOMELESSES,
    SET_HOMELESSES,
    SET_HOMELESSES_ERROR,
    GET_HOMELESS_INFO,
    SET_HOMELESS_INFO_ERROR,
    SET_HOMELESS_INFO,
    RESET_HOMELESS_INFO,
    ADD_MEMBER_TO_MY_MEMBERS,
    REMOVE_MEMBER_FROM_MY_MEMBERS,
    UPDATE_HOMELESS_BALANCE,
    GET_HOMELESS_TOUCHPOINTS,
    CANCEL_GET_TOUCHPOINT_QUESTIONS,
    SET_HOMELESS_TOUCHPOINTS,
    GET_HOMELESS_TIMELINE,
    SET_HOMELESS_TIMELINE,
    GET_HOMELESS_GOALS,
    SET_HOMELESS_GOALS,
    GET_HOMELESS_GOAL_ACTIONS,
    SET_HOMELESS_GOAL_ACTIONS,
    UPDATE_HOMELESS_GOAL_ACTION_STATUS,
    UPDATE_HOMELESS_GOAL_ACTION_MATCH_AMOUNT,
    UPDATE_HOMELESS_GOAL_ACTION_PENDING_PAYMENTS,
    REMOVE_HOMELESS_GOAL_ACTION,
    SHOW_GOAL_ACTION_COMPLETE_POPUP,
    SET_GOAL_ACTION_COMPLETE_POPUP,
    GET_HOMELESS_NEEDS,
    SET_HOMELESS_NEEDS,
    REMOVE_HOMELESS_NEED,
    GET_NEWSFEED,
    SET_NEWSFEED,
    GET_MONTHLY_SUMMARY,
    SET_MONTHLY_SUMMARY,
    UNAUTHORIZED_USER,
    RESET_UNAUTHORIZED,
    GET_API_ERROR,
    SET_INTAKE_ERROR,
    RESET_API_ERROR,
    GET_BEACON_RENEW,
    SET_BEACON_RENEW,
    RESET,
    SET_LOCAL_TOUCHPOINT,
    REMOVE_LOCAL_TOUCHPOINT,
    SET_LOCAL_TOUCHPOINT_DESCRIPTION,
    SET_LOCAL_COMPLETION_ON_GOAL_ACTION,
    SET_LOCAL_COMPLETION_ON_NEED,
    SET_LOCAL_TOUCHPOINT_GOAL_ACTIONS,
    SET_LOCAL_TOUCHPOINT_NEEDS,
    SET_LOCAL_TOUCHPOINT_ANSWER,
    SET_LOCAL_TOUCHPOINT_ANSWER_CONTEXT,
    SET_LOCAL_PREVIOUS_TOUCHPOINTS,
    SET_REWARDABILITY_ON_GOAL_ACTION,
    GET_DETERMINANT_RANKS,
    SET_DETERMINANT_RANKS,
    GET_SKILLS,
    CANCEL_GET_SKILLS,
    SET_SKILLS,
    SET_LAYOUT_TITLES,
    SET_BACK_BUTTON_URL,
    GET_ORGANIZATION,
    SET_ORGANIZATION,
    GET_ORGANIZATION_TOUCHPOINTS,
    SET_ORGANIZATION_TOUCHPOINTS,
    GET_ORGANIZATION_PAYMENTS,
    SET_ORGANIZATION_PAYMENTS,
    GET_MEMBER_PAYMENTS,
    SET_MEMBER_PAYMENTS,
    SET_FULL_MEMBER_PAYMENTS,
    RESET_FULL_MEMBER_PAYMENTS,
    GET_ORGANIZATION_ENCOURAGEMENTS,
    SET_ORGANIZATION_ENCOURAGEMENTS,
    RESET_INTAKE,
    DECREMENT_INTAKE_STEP,
    INCREMENT_INTAKE_STEP,
    SET_INTAKE_DEPLOYMENT_ID,
    SET_INTAKE_PROFILE_PICTURE,
    SET_INTAKE_PICTURE_TOGGLE,
    SET_INTAKE_PROFILE_NAME,
    SET_INTAKE_GOALS,
    SET_INTAKE_NEEDS,
    SET_INTAKE_LIFESTORY,
    SET_INTAKE_SKILLS,
    REMOVE_INTAKE_SKILL,
    SET_INTAKE_BIRTH_DAY,
    SET_INTAKE_BIRTH_MONTH,
    SET_INTAKE_EMAIL,
    SET_INTAKE_PHONE_NUMBER,
    SET_INTAKE_QUESTION_ANSWER,
    TOGGLE_INTAKE_AGREED,
    SET_INTAKE_BEACON_ID,
    GET_INTAKE_SUBMIT,
    SET_INTAKE_SUBMIT,
    GET_INTAKE_TOUCHPOINT_SUBMIT,
    SET_INTAKE_TOUCHPOINT_SUBMIT,
    SET_INTAKE_TOUCHPOINT_ERROR,
    SET_INTAKE_TOUCHPOINT_ANSWER,
    SET_INTAKE_ICEBREAKER,
    SET_INTAKE_QR,
    SET_INTAKE_RELEVANT_ID,
    SET_INTAKE_INTERVIEWER,
    SET_INTAKE_INTERVIEWER_ID,
    SET_INTAKE_PRIVATE_FIRST_NAME,
    SET_INTAKE_PRIVATE_MIDDLE_NAME,
    SET_INTAKE_PRIVATE_LAST_NAME,
    SET_INTAKE_PRIVATE_FULL_NAME,
    SET_INTAKE_ORG_ID,
    SET_INTAKE_PROGRESS_WIDTH,
    SET_UNENROLLMENT_STATE,
    RESET_UNENROLLMENT_STATE,
    SET_UNENROLLMENT_SDOH_STATUS,
    SET_UNENROLLMENT_REDEEM_STATUS,
    GET_CHAT_ROOMS,
    GET_CHAT_ROOMS_SUCCESS,
    GET_CHAT_MESSAGES,
    GET_CHAT_MESSAGES_SUCCESS,
    SET_ALL_CHAT_MESSAGES,
    SEND_MESSAGES,
    SEND_MESSAGES_SUCCESS,
    SET_CHAT_ROOM_RECIPIENTS,
    RESEND_MESSAGE,
    RESEND_MESSAGE_SUCCESS,
    DELETE_MESSAGE,
    SET_MESSAGE_HOLDER_ID,
    RESET_MESSAGE_INFO,
    GET_QUALITY_OF_LIFE_MEASURES,
    SET_QUALITY_OF_LIFE_MEASURES,
    SET_TOUCHPOINT_QUESTION_QUALITY_OF_LIFE_MEASURES,
    SUBMIT_STATUS_CAPTURE,
    SET_VIEW_GUIDE_MESSAGE,
    FETCH_PEX_HISTORY,
    SET_PEX_HISTORY,
    SET_FULL_PEX_HISTORY,
    GET_HOMELESS_PEX_INFO,
    SET_HOMELESS_PEX_INFO,
    SET_HOMELESS_PEX_BALANCE,
    UPDATE_HOMELESS_PEX_BALANCE,
    FETCH_HOMELESS_LIFESTORY,
    SET_HOMELESS_LIFESTORY,
    UPDATE_HOMELESS_LIFESTORY,
    FETCH_MEMBER_HISTORY,
    SET_MEMBER_HISTORY,
    FETCH_HISTORY_FILTERS,
    SET_HISTORY_FILTERS,
    UPDATE_BONUS_AMOUNT,
    SET_MAX_BONUS_AMOUNT,
    UPDATE_SHOW_GUIDE_SETTING,
    UPDATE_LANGUAGE_SETTING,
    UPDATE_HOMELESSES_LANGUAGE_PREFERENCE,
    SET_USER,
    UNSET_USER,
    SET_LANGUAGES,
    SET_HOMELESSES_LANGUAGES,
    INCREMENT_ENROLLED_MEMBERS,
    SET_PROFILE_COMPLETION,
    UPDATE_PROFILE_COMPLETION,
    SET_PROFILE_COMPLETED_ITEMS,
    UPDATE_PROFILE_COMPLETED_ITEMS,
    UPDATE_HOMELESS_INFO_FIELD,
    GET_DEPLOYMENT,
    SET_DEPLOYMENT,
    GET_DEPLOYMENT_ORGS,
    SET_DEPLOYMENT_ORGS,
    GET_DEPLOYMENT_USERS,
    SET_DEPLOYMENT_USERS,
    REFER_MEMBER,
    REFER_MEMBER_FAIL,
    REFER_MEMBER_SUCCESS,
    COMPLETE_CATCH_UP_ITEM,
    FETCH_CATCH_UP_ITEMS,
    SET_CATCH_UP_ITEMS,
    SNOOZE_CATCH_UP_ITEM,
    UPDATE_ITEM,
    ASSIGN_DEBIT_CARD,
    FETCH_DEBIT_CARD_HISTORY,
    GET_DEBIT_CARD_INFO,
    GET_DEBIT_CARD_OPTIONS,
    SET_DEBIT_CARD_OPTIONS,
    SET_DEBIT_CARD_BALANCE,
    SET_DEBIT_CARD_FULL_HISTORY,
    SET_DEBIT_CARD_HISTORY,
    SET_DEBIT_CARD_INFO,
    UPDATE_DEBIT_CARD_BALANCE,
    UPDATE_DEBIT_CARD_PIN,
    UPDATE_HOMELESS_CARD_BALANCE
};

export default constants;
