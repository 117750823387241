import SamaritanFetch from './httpClient';

export const fetchHistoryFilters = () => {
    return SamaritanFetch.v3(`/member_histories/filters`, {
        method: 'GET',
        contentType: 'application/json'
    });
};

export const fetchHomelessHistory = (
    memberId: number,
    signal?: AbortSignal,
    filterType?: string,
    startDate?: string,
    endDate?: string
) => {
    return SamaritanFetch.v3(`/member_histories`, {
        method: 'GET',
        contentType: 'application/json',
        query: {
            'member_histories[homeless_id]': memberId,
            'member_histories[filter_type]': filterType,
            'member_histories[start_date]': startDate,
            'member_histories[end_date]': endDate
        },
        signal: signal
    })
        .then((res: Response) => res.json())
        .then((data: { member_histories: object[] }) => data.member_histories);
};

export const fetchHomelessInfo = (memberId: number) => {
    return SamaritanFetch.v3(
        `/samaritan_members/${memberId}?samaritan_member[relations][organization]{}&samaritan_member[relations][skills]{}&samaritan_member[relations][qr_code]{}`
    );
};

export const fetchHomelessLifeStory = (memberId: number) => {
    return SamaritanFetch.v3(`/holder_life_updates`, {
        method: 'GET',
        query: {
            homeless_id: memberId
        }
    });
};

export const unenrollHomeless = (memberId: number, data: object) => {
    return SamaritanFetch.v3(`/samaritan_members/${memberId}`, {
        method: 'PUT',
        body: data
    });
};

// is this even used anymore?
export const updateHomelessDescription = (
    beaconId: string,
    newDescription: string
) => {
    const payload = {
        bio_only: 'true',
        description: newDescription,
        send_email_update: 'false'
    };

    return SamaritanFetch.v2.put(`/homelesses/${beaconId}`, {
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const updateHomelessInfo = (memberId: number, data: object) => {
    return SamaritanFetch.v3(`/samaritan_members/${memberId}`, {
        method: 'PUT',
        body: data
    });
};

export const updateHomelessLifeStory = (formData: object) => {
    return SamaritanFetch.v3(`/holder_life_updates`, {
        method: 'POST',
        body: formData
    });
};

export const updateHomelessPrivateInfo = (memberId: number, data: object) => {
    return SamaritanFetch.v3(`/samaritan_members/${memberId}`, {
        method: 'PUT',
        body: data
    });
};

export const updateHomelessPublicInfo = (memberId: number, data: object) => {
    return SamaritanFetch.v3(`/samaritan_members/${memberId}`, {
        method: 'PUT',
        body: data
    });
};

