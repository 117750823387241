import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import AssignCardPopUp from '../../AssignCardPopUp';
import Logo from 'assets/logo.png';
import NumPad from '../NumPad';
import PopUp from '../../../PopUp';
import { DetailButton } from '../Buttons';

const useStyles = makeStyles(() => ({
    balance: {
        fontSize: '32px',
        fontWeight: '700',
        marginRight: '6px'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'fit-content',
        padding: '40px 0 24px 0',
        gap: '30px'
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    debitCard: {
        width: '420px',
        height: '180px',
        borderRadius: '16px',
        background: '#739BE8'
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '65%',
        padding: '20px',
        color: '#FFFFFF'
    },
    detailsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '70%',
        margin: 'auto',
        padding: '50px 30px 30px 30px'
    },
    logo: {
        width: 'auto',
        height: '32px'
    }
}));

const DetailsView = ({
    assignView,
    changeView,
    debitBalance,
    debitCardOptions,
    debitInfo,
    debitLast4,
    debitType,
    fromCatchUp,
    giveAutoTransfer,
    handleModalClick,
    id,
    memberBalance,
    name,
    needs,
    pexAutoTransfer,
    photo,
    replaceView,
    replaceCardView,
    setAssignDebit,
    setAssignView,
    setChangeView,
    setDebitBalance,
    setDebitLast4,
    setDebitType,
    setMemberBalance,
    setTransferView,
    setReplaceView,
    transferView,
    updatePinView
}) => {
    const classes = useStyles();

    const [amount, setAmount] = useState(null);
    const [assignPopUp, setAssignPopUp] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    const renderDebitType = () => {
        if (debitType === 'pex') {
            return 'PEX Card';
        } else if (debitType === 'givecard') {
            return 'GiveCard';
        } else {
            return 'Debit Card';
        }
    };

    useEffect(() => {
        if (
            debitInfo &&
            debitInfo.card_type &&
            debitInfo.card_type !== debitType &&
            !replaceView &&
            (!giveAutoTransfer || !pexAutoTransfer)
        ) {
            setDebitType(debitInfo.card_type);
        }
    }, [
        debitInfo,
        debitType,
        giveAutoTransfer,
        pexAutoTransfer,
        replaceView,
        setDebitType
    ]);

    return (
        <>
            {!transferView && !changeView && !replaceView && !assignView ? (
                <div className={classes.detailsWrapper}>
                    <div className={classes.debitCard}>
                        <div className={classes.detailsContainer}>
                            <div className={classes.cardHeader}>
                                <img
                                    src={Logo}
                                    alt="Samaritan Logo"
                                    className={classes.logo}
                                />
                                <span
                                    style={{
                                        fontWeight: '600',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    {renderDebitType()} ending in{' '}
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: '800'
                                        }}
                                    >
                                        {debitLast4}
                                    </span>
                                </span>
                            </div>
                            <div className={classes.balanceContainer}>
                                <span className={classes.balance}>
                                    ${debitInfo?.available_balance}
                                </span>
                                <span>available</span>
                            </div>
                        </div>
                    </div>
                    <div className={classes.buttonWrapper}>
                        {!updatePinView &&
                            !replaceCardView &&
                            (!giveAutoTransfer || !pexAutoTransfer) && (
                                <DetailButton
                                    handleClick={setTransferView}
                                    icon={`fas fa-plus`}
                                    label={`Transfer Funds`}
                                />
                            )}
                        <DetailButton
                            handleClick={setChangeView}
                            icon={`far fa-lock-alt`}
                            label={`Update PIN`}
                        />
                        <DetailButton
                            handleClick={() => {
                                if (debitCardOptions?.length > 1) {
                                    setAssignPopUp(true);
                                } else {
                                    if (debitCardOptions?.length === 1) {
                                        setDebitType(debitCardOptions[0].type);
                                    }
                                    setReplaceView(true);
                                }
                            }}
                            icon={`far fa-sync`}
                            label={`Replace Card`}
                        />
                    </div>
                </div>
            ) : (
                <NumPad
                    fromCatchUp={fromCatchUp}
                    memberBalance={memberBalance}
                    setMemberBalance={setMemberBalance}
                    debitBalance={debitBalance}
                    setDebitBalance={setDebitBalance}
                    debitLast4={debitLast4}
                    setDebitLast4={setDebitLast4}
                    id={id}
                    name={name}
                    assignView={assignView}
                    setAssignView={setAssignView}
                    transferView={transferView}
                    setTransferView={setTransferView}
                    changeView={changeView}
                    setChangeView={setChangeView}
                    replaceView={replaceView}
                    setReplaceView={setReplaceView}
                    amount={amount}
                    setAmount={setAmount}
                    setShowPopup={setShowPopUp}
                    setAssignDebit={setAssignDebit}
                    handleModalClick={handleModalClick}
                    updatePinView={updatePinView}
                    replaceCardView={replaceCardView}
                    debitCardOptions={debitCardOptions}
                    debitType={debitType}
                    setDebitType={setDebitType}
                />
            )}
            {showPopUp && (
                <PopUp
                    content="update need"
                    amount={amount}
                    handleModalClick={() => setShowPopUp(false)}
                    name={name}
                    homelessNeeds={needs}
                />
            )}
            {assignPopUp && (
                <AssignCardPopUp
                    debitCardOptions={debitCardOptions}
                    handleModalClick={() => setAssignPopUp(false)}
                    photo={photo}
                    setAssignPopUp={setAssignPopUp}
                    setDebitType={setDebitType}
                    setReplaceView={setReplaceView}
                />
            )}
        </>
    );
};

DetailsView.propTypes = {
    memberBalance: PropTypes.number,
    setMemberBalance: PropTypes.func,
    id: PropTypes.number.isRequired
};

export default DetailsView;
