import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    editIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    editPosition: {
        position: 'absolute',
        right: '-20px',
        top: '-16px'
    },
    editIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFFFFF',
        border: 'none',
        borderRadius: 40,
        boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
        color: '#2B3165',
        fontSize: '1.2rem',
        minHeight: '40px !important',
        minWidth: '40px !important',
        outline: 'none'
    },
    saveBtn: {
        backgroundColor: '#7378E8',
        borderRadius: '40px',
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: 800,
        textTransform: 'none',
        padding: '6px 12px'
    }
}));

type EditIconProps = {
    edit?: boolean;
    setEdit: (edit: boolean) => void;
    handleImageClick?: () => void;
    handleSave?: () => void;
    newProfilePicture?: boolean;
};

const EditIcon: React.FC<EditIconProps> = ({
    edit,
    setEdit,
    handleImageClick,
    handleSave,
    newProfilePicture
}) => {
    const classes = useStyles();

    const renderClasses = () => {
        if (!edit && !newProfilePicture) {
            return `${classes.editPosition} ${classes.editIconContainer}`;
        } else {
            return classes.editIconContainer;
        }
    };

    const handleEdit = () => {
        if (handleImageClick) {
            ReactGA.event({
                category: 'Holder Profile',
                action: 'Edit Profile Picture'
            });

            handleImageClick();
        } else {
            ReactGA.event({
                category: 'Holder Profile',
                action: 'Edit Profile Name'
            });

            setEdit(true);
        }
    };

    const handleSaveChanges = () => {
        if (handleSave) {
            handleSave();
        }
        setEdit(false);
    };

    return (
        <div className={renderClasses()}>
            {!edit && !newProfilePicture ? (
                <Button
                    className={classes.editIcon}
                    onClick={() => handleEdit()}
                >
                    <i className="far fa-pencil" />
                </Button>
            ) : (
                <Button
                    className={classes.saveBtn}
                    onClick={() => handleSaveChanges()}
                >
                    Save
                </Button>
            )}
        </div>
    );
};

export default EditIcon;
