import SamaritanFetch from './httpClient';

export const assignPexCard = (
    memberId: number,
    debitNums: number,
    token: string
) => {
    const pexNums = { last4: debitNums };

    SamaritanFetch.v3.post(`/samaritan_members/${memberId}/pex/card/assign`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token=${token}`
        },
        body: JSON.stringify(pexNums)
    });
};

export const transferPexFunds = (
    memberId: number,
    balance: number,
    token: string
) => {
    const transferBalance = { amount: balance };

    SamaritanFetch.v3.post(`/samaritan_members/${memberId}/pex/card/fund`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token=${token}`
        },
        body: JSON.stringify(transferBalance)
    });
};

export const updatePexPin = (memberId: number, pin: number, token: string) => {
    const newPin = { new_pin: pin };

    SamaritanFetch.v3.post(
        `/samaritan_members/${memberId}/pex/card/update_pin`,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`
            },
            body: JSON.stringify(newPin)
        }
    );
};

export const fetchPexHistory = (memberID: number, full: boolean) => {
    const query = {} as {
        page?: number;
        page_size?: number;
    };

    if (!full) {
        query.page = 1;
        query.page_size = 3;
    }

    return SamaritanFetch.v3(
        `/samaritan_members/${memberID}/pex/account/transaction_details`,
        {
            query
        }
    );
};
