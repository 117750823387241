import classNames from 'classnames';

import Slide from '@mui/material/Slide';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        notificationBanner: {
            position: 'absolute',
            top: '10px',
            right: '16px',
            padding: '12px 0',
            minWidth: 'fit-content',
            marginLeft: '16px',
            borderRadius: '8px',
            fontWeight: 500,
            zIndex: 1,
            '& span': {
                margin: '0 16px'
            }
        },
        removed: {
            background: '#FFF4E7',
            color: '#A9610B'
        },
        saved: {
            background: '#E7FEF4',
            color: '#0BA162'
        }
    })
);

type NotificationBannerProps = {
    name: string;
    saved: boolean;
    showNotification: boolean;
};

const NotificationBanner: React.FC<NotificationBannerProps> = ({
    name,
    saved,
    showNotification
}) => {
    const classes = useStyles();

    const notificationBannerClass = saved ? classes.saved : classes.removed;

    const notificationStyles = classNames(
        classes.notificationBanner,
        notificationBannerClass
    );

    const notificationText = saved ? (
        <>
            <i className="fas fa-check-circle" />
            <span>
                {name} is saved to your <b>"My Members"</b> list
            </span>
        </>
    ) : (
        <>
            <i className="fas fa-check-circle" />
            <span>
                {name} is removed from your <b>"My Members"</b> list
            </span>
        </>
    );

    return (
        <Slide direction="down" in={showNotification}>
            <div className={notificationStyles}>
                <span>{notificationText}</span>
            </div>
        </Slide>
    );
};

export default NotificationBanner;
