import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import store from 'store2';

import { HeaderProps } from 'types/interfaces';

import Banner from 'assets/profile-banner.png';
import NotificationBanner from './components/NotificationBanner';
import CompleteProfileItems from './components/CompleteProfileItems';
import CompleteProfileProgress from './components/CompleteProfileProgress';
import ReferMember from './components/ReferMember';
import SaveMember from './components/SaveMember';
import Tooltip from './components/Tooltip';
import UnenrollHeader from './components/Unenroll';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        banner: {
            display: 'flex',
            '& img': {
                minHeight: '150px',
                objectFit: 'cover',
                opacity: 0.8,
                width: '100%',
                '@media (max-width: 768px)': {
                    minHeight: '100px'
                }
            }
        },
        buttonContainer: {
            position: 'absolute',
            right: '30px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            margin: '10px 0',
            width: '100%',
            '@media (max-width: 768px)': {
                top: 0
            }
        },
        headerWrapper: {
            position: 'relative',
            padding: '120px 0px 50px 0px',
            '@media (max-width: 768px)': {
                padding: '100px 0 50px 0'
            }
        },
        photoContainer: {
            position: 'absolute',
            top: '-60px',
            left: '5%',
            '& img': {
                borderRadius: '50%',
                boxShadow: '0 0 0 5px #fff',
                height: '160px',
                objectFit: 'cover',
                width: '160px'
            },
            '@media (max-width: 768px)': {
                top: '-25%',
                left: '10%'
            }
        },
        exited: {
            '& img': {
                boxShadow: '0 0 0 5px #FEF3E7'
            }
        },
        bannerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '20px 5% 0 5%'
        },
        memberInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            color: '#1F2152'
        },
        memberName: {
            fontSize: '28px',
            fontWeight: 700,
            lineHeight: '34px',
            marginBottom: '10px'
        },
        memberID: {
            fontSize: '17px',
            lineHeight: '21px'
        },
        active: {
            background: '#7378E8 !important',
            color: '#fff !important'
        }
    })
);

const Header: React.FC<HeaderProps> = ({
    caseloads,
    createMemberCaseload,
    deploymentOrgs,
    exited,
    holder,
    profileItems,
    removeMemberFromMyMembers,
    saveMemberToMyMembers,
    setCompleteProfilePopup,
    setReenroll,
    setReferMemberPopup,
    userID
}) => {
    const classes = useStyles();
    const isInitialRender = useRef(true);

    const {
        first_name,
        id,
        last_name,
        member_id,
        middle_name,
        mobile_photo,
        name,
        unenrolled_on
    } = holder;

    const activeReferral = caseloads?.find(
        (caseload) =>
            caseload.homeless_id === id && caseload.status === 'pending'
    );
    const caseload = caseloads?.find((caseload) => caseload.homeless_id === id);
    const getLocalStorageTooltip = store.get('renderSaveMemberTooltip');
    const hasViewedTooltip =
        getLocalStorageTooltip && getLocalStorageTooltip[userID] ? true : false;
    const isSaved = caseloads?.some(
        (caseload: { homeless_id: number; status: string }) =>
            caseload.homeless_id === id && caseload.status === 'active'
    );
    const profilePercentage =
        (Object.keys(profileItems).filter(
            (item) =>
                profileItems[item].status === 'complete' ||
                profileItems[item].status === 'pending'
        ).length /
            Object.keys(profileItems).length) *
        100;

    const [saved, setSaved] = useState(isSaved);
    const [showNotification, setShowNotification] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);

    const photoContainerClass = classNames({
        [classes.exited]: exited,
        [classes.photoContainer]: true
    });

    const renderMemberName = () => {
        const trimmedFirstName = (first_name || '').trim();
        const trimmedLastName = (last_name || '').trim();
        const trimmedMiddleName = (middle_name || '').trim();

        if (!trimmedFirstName && !trimmedMiddleName && !trimmedLastName) {
            return name;
        }

        return `${trimmedFirstName} ${trimmedMiddleName} ${trimmedLastName}`.trim();
    };

    useEffect(() => {
        if (!isInitialRender.current) {
            setShowNotification(true);

            const timeout = setTimeout(() => {
                setShowNotification(false);
            }, 3000);

            return () => clearTimeout(timeout);
        } else {
            isInitialRender.current = false;
        }
    }, [saved]);

    return (
        <>
            <div className={classes.banner}>
                <img src={Banner} alt="banner" />
            </div>
            {exited && (
                <UnenrollHeader
                    unenrolled_on={unenrolled_on}
                    setReenroll={setReenroll}
                />
            )}
            {!caseload && !hasViewedTooltip && showTooltip && (
                <Tooltip
                    name={name}
                    userID={userID}
                    setShowTooltip={setShowTooltip}
                />
            )}
            <div className={classes.buttonContainer}>
                {!exited && deploymentOrgs?.length && (
                    <ReferMember
                        isSaved={isSaved}
                        setReferMemberPopup={setReferMemberPopup}
                    />
                )}
                {!exited && (
                    <SaveMember
                        activeReferral={activeReferral}
                        caseload={caseload}
                        caseloadID={caseload?.id || null}
                        createMemberCaseload={createMemberCaseload}
                        hasViewedTooltip={hasViewedTooltip}
                        isSaved={isSaved}
                        memberID={id}
                        removeMemberFromMyMembers={removeMemberFromMyMembers}
                        saved={saved}
                        saveMemberToMyMembers={saveMemberToMyMembers}
                        showTooltip={showTooltip}
                        setSaved={setSaved}
                    />
                )}
            </div>
            {showNotification && (
                <NotificationBanner
                    name={name}
                    saved={saved}
                    showNotification={showNotification}
                />
            )}
            <div className={classes.headerWrapper}>
                {!exited && profilePercentage !== 100 && (
                    <CompleteProfileItems
                        profileItems={profileItems}
                        setCompleteProfilePopup={setCompleteProfilePopup}
                    />
                )}
                <div className={photoContainerClass}>
                    <img src={mobile_photo} alt="profile" />
                    {!exited && (
                        <CompleteProfileProgress
                            percentage={profilePercentage}
                        />
                    )}
                </div>
                <div className={classes.bannerContainer}>
                    <div className={classes.memberInfoContainer}>
                        <span className={classes.memberName}>
                            {renderMemberName()}
                        </span>
                        <span className={classes.memberID}>
                            Insurance ID: {member_id ? member_id : '-'}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
