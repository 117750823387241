import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import AddInfo from '../../ProfileCompletion/components/AddInfo';
import Card from '../../CaseManagement/components/Card';
import DebitView from './Views/DebitView';
import LoadingCircle from 'components/_shared/LoadingCircle';
import PINView from './Views/PINView';
import TransferView from './Views/TransferView';
import UpdateModal from 'components/_shared/UpdateModal';
import { BackButton } from './Buttons';
import { CTAButton1 } from 'components/_shared/buttons';

import AuthManager from 'auth';
import SamaritanFetch from 'api/httpClient';

import {
    updateHomelessBalance,
    updateHomelessCardBalance
} from 'redux/actions/homeless';
import {
    setDebitCardInfo,
    updateDebitCardBalance
} from 'redux/actions/debit_card';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    numPadWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .row': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 16px 0px 16px',
            '& .btn': {
                height: '56px',
                width: '56px',
                fontFamily: 'Manrope',
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '31px',
                color: '#1F2152'
            }
        }
    },
    assignBtn: {
        margin: '8px 0 0 0',
        height: '60px',
        background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
        fontSize: '16px'
    },
    disableBtn: {
        opacity: '0.5'
    },
    errorBtn: {
        background: 'linear-gradient(180deg, #F5577A 0%, #F5577A 100%)'
    },
    successBtn: {
        background: 'linear-gradient(180deg, #7FE8B2 0%, #7FE8B2 100%)'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        margin: 'auto',
        width: '100%',
        maxWidth: '320px'
    },
    cardContainer: {
        display: 'flex',
        '& #title': {
            alignSelf: 'center',
            paddingLeft: '8px'
        }
    },
    cardWrapper: {
        margin: 'auto'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '800'
    },
    dividingLine: {
        boxSizing: 'border-box',
        maxWidth: '100%',
        marginInline: 20,
        height: 1,
        background: '#E3E3FA',
        marginTop: 15
    },
    assignBody: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px 0 0 0'
    },
    messageContainer: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
        color: ' #1F2152',
        textAlign: 'center',
        paddingTop: '8px',
        margin: 'auto'
    },
    errorMessage: {
        color: '#F5577A'
    },
    successMessage: {
        color: '#7FE8B2'
    }
}));

export default function NumPad({
    fromCatchUp,
    id,
    name,
    memberBalance,
    setMemberBalance,
    debitBalance,
    setDebitBalance,
    debitLast4,
    setDebitLast4,
    assignView,
    setAssignView,
    changeView,
    setChangeView,
    replaceView,
    setReplaceView,
    transferView,
    setTransferView,
    setShowPopup,
    setAmount,
    setAssignDebit,
    handleModalClick,
    updatePinView,
    replaceCardView,
    debitCardOptions,
    debitType,
    setDebitType
}) {
    const apiToken = AuthManager.getInstance().getApiToken();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [nums, setNums] = useState(``);

    const [disabled, setDisabled] = useState(true);
    const [externalID, setExternalID] = useState(``);
    const [externalIDPopUp, setExternalIDPopUp] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(``);
    const [success, setSuccess] = useState(false);

    const apiURL = debitType === `givecard` ? `givecard` : `pex/card`;

    const inputLayout = [`1 2 3`, `4 5 6`, `7 8 9`, `. 0 <`];

    const keyInputs = (e) => {
        if (isFinite(e.key)) {
            if (!transferView) {
                if (nums.length < 4) {
                    setNums(nums + e.key);
                }
                validateDebitNum(nums);
            } else {
                if (
                    (nums.length === 0 && e.key === `.`) ||
                    (nums.length === 0 && e.key === `0`) ||
                    (nums.includes(`.`) && e.key === `.`) ||
                    (nums.includes(`.`) &&
                        nums.split(`.`)[1].length === 2 &&
                        e.key !== `Delete`)
                ) {
                    return;
                }

                if (nums.length < 4) {
                    setNums(nums + e.key);
                }
            }
        }

        if (e.key === `Backspace` || e.key === `Delete`) {
            setNums(nums.slice(0, nums.length - 1));
        }
    };

    const numberInput = (button) => {
        if (!transferView) {
            if (button === `<`) {
                setNums(nums.slice(0, nums.length - 1));
            } else {
                if (nums.length < 4) {
                    setNums(nums + button);
                }
                validateDebitNum(nums);
            }
        } else {
            if (button === `<`) {
                setNums(nums.slice(0, nums.length - 1));
            } else {
                if (
                    (nums.length === 0 && button === `.`) ||
                    (nums.length === 0 && button === `0`) ||
                    (nums.includes(`.`) && button === `.`) ||
                    (nums.includes(`.`) &&
                        nums.split(`.`)[1].length === 2 &&
                        button !== `<`)
                ) {
                    return;
                }

                if (!nums.includes(`.`) && nums.length < 4) {
                    setNums(nums + button);
                } else {
                    if (button === `.`) {
                        setNums(nums + button);
                    }

                    if (nums.includes(`.`) && nums.length < 7) {
                        setNums(nums + button);
                    }
                }
            }
        }

        setError(false);
        setSuccess(false);
        setMessage(``);
    };

    const validateDebitNum = useCallback(
        (num) => {
            if (/^\d+$/.test(num) && num.length > 3 && debitType) {
                setDisabled(false);
                setError(false);
                setSuccess(false);
            }
        },
        [debitType]
    );

    const handleResponse = (response) => {
        if (response && response.status === 200) {
            setLoading(false);
            setNums(``);
            setSuccess(true);
        } else {
            setLoading(false);
            setError(true);
        }
    };

    const assignDebit = (id, loadingFn, errorFn, errorMessageFn) => {
        const debitNums = { last4: nums };
        setLoading(true);

        if (externalID.length > 0) {
            debitNums.external_id = externalID;
        }

        SamaritanFetch.v3
            .post(
                `/samaritan_members/${id}/${apiURL}${
                    debitType === 'givecard' ? `/card` : ''
                }/assign`,
                {
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Token token=${apiToken}`
                    },
                    body: JSON.stringify(debitNums)
                }
            )
            .then((res) => {
                handleResponse(res);
                return res.json();
            })
            .then((data) => {
                if (data.errors) {
                    const errorMessage = data.errors[0].description;

                    if (loadingFn) {
                        loadingFn(false);
                    }

                    if (errorFn) {
                        errorFn(true);
                    }

                    if (errorMessageFn) {
                        errorMessageFn(errorMessage);
                    }

                    if (
                        errorMessage !== 'too many possible cards for last4' &&
                        !externalID.length
                    ) {
                        setMessage(errorMessage);
                        setNums(``);
                    }

                    if (errorMessage === 'too many possible cards for last4') {
                        setExternalID(``);
                        setExternalIDPopUp(true);
                    }
                } else {
                    if (debitType === 'pex') {
                        setDebitBalance(
                            data.samaritan_member.pex_available_balance
                        );
                        setDebitLast4(data.samaritan_member.pex_card_last4);
                        setMessage(
                            `Card ending in ${data.samaritan_member.pex_card_last4} assigned to ${name} 🎉`
                        );
                    } else {
                        setDebitBalance(
                            data.samaritan_member.card_available_balance
                        );
                        setDebitLast4(data.samaritan_member.card_last4);
                        setMessage(
                            `Card ending in ${data.samaritan_member.card_last4} assigned to ${name} 🎉`
                        );
                    }

                    if (replaceView) {
                        const cardData = data.samaritan_member;

                        const {
                            card_account_id,
                            pex_account_id,
                            card_last4,
                            pex_card_last4,
                            card_total_balance,
                            pex_total_balance,
                            card_available_balance,
                            pex_available_balance
                        } = cardData;

                        const isGiveCard = debitType === 'givecard';

                        const cardInfo = {
                            account_id: isGiveCard
                                ? card_account_id
                                : pex_account_id,
                            card_last4: isGiveCard
                                ? card_last4
                                : pex_card_last4,
                            card_type: isGiveCard ? 'givecard' : 'pex',
                            total_balance: isGiveCard
                                ? card_total_balance
                                : pex_total_balance,
                            available_balance: isGiveCard
                                ? card_available_balance
                                : pex_available_balance
                        };

                        dispatch(setDebitCardInfo(cardInfo));
                    }

                    setTimeout(() => {
                        setAssignView(false);
                        setExternalIDPopUp(false);
                        handleModalClick();

                        if (setAssignDebit || replaceCardView) {
                            if (setAssignDebit) {
                                setAssignDebit(true);
                            }
                            handleModalClick();
                        } else {
                            history.go(0, { from: 'account' });
                        }
                    }, 1000);
                }
            });
    };

    const updatePIN = (id) => {
        const pinNums = { new_pin: nums };
        setLoading(true);

        SamaritanFetch.v3
            .post(`/samaritan_members/${id}/${apiURL}/update_pin`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Token token=${apiToken}`
                },
                body: JSON.stringify(pinNums)
            })
            .then((res) => {
                handleResponse(res);
                return res.json();
            })
            .then((data) => {
                if (data.errors) {
                    setMessage(data.errors[0].description);
                } else {
                    setMessage(`Pin Updated 🎉`);

                    setTimeout(() => {
                        if (updatePinView) {
                            handleModalClick();
                        }

                        setChangeView(false);
                    }, 2000);
                }
            });
    };

    const transferBalance = (id) => {
        const transferData = { amount: parseFloat(nums) };
        setLoading(true);

        SamaritanFetch.v3
            .post(`/samaritan_members/${id}/${apiURL}/fund`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Token token=${apiToken}`
                },
                body: JSON.stringify(transferData)
            })
            .then((res) => {
                handleResponse(res);
                return res.json();
            })
            .then((data) => {
                if (data.errors) {
                    setMessage(data.errors[0].description);
                } else {
                    setDebitBalance(data.message.available);
                    dispatch(updateDebitCardBalance(data.message.available));
                    setMemberBalance(memberBalance - parseFloat(nums));
                    setMessage(`$${nums} transferred to debit card 🎉`);

                    setTimeout(() => {
                        setTransferView(false);
                        setAmount(transferData.amount);
                        setShowPopup(true);

                        dispatch(updateHomelessBalance(parseFloat(nums) * -1));
                        dispatch(updateHomelessCardBalance(parseFloat(nums)));
                    }, 2000);
                }
            });
    };

    const buttonStyles = () => {
        if (error) {
            return `${classes.assignBtn} ${classes.errorBtn}`;
        }

        if (success) {
            return `${classes.assignBtn} ${classes.successBtn}`;
        }

        if (disabled) {
            return `${classes.assignBtn} ${classes.disableBtn}`;
        }

        return classes.assignBtn;
    };

    const messageStyles = () => {
        if (error) {
            return `${classes.messageContainer} ${classes.errorMessage}`;
        }

        if (success) {
            return `${classes.messageContainer} ${classes.successMessage}`;
        }

        return classes.messageContainer;
    };

    const handleDisplayForm = () => {
        if (replaceCardView || updatePinView) {
            handleModalClick();
        }

        if (assignView) {
            setAssignView(false);
        }

        if (changeView) {
            setChangeView(false);
        }

        if (replaceView) {
            setReplaceView(false);
        }

        if (transferView) {
            setTransferView(false);
        }
    };

    const handleExternalModalClick = () => {
        setError(false);
        setExternalIDPopUp(false);
    };

    const handleSubmitForm = (id) => {
        if (changeView) {
            updatePIN(id);
        }

        if (assignView || replaceView) {
            assignDebit(id);
        }

        if (transferView) {
            transferBalance(id);
        }
    };

    const renderHeader = () => {
        if (transferView) {
            return `Transfer - Debit Card ${debitLast4}`;
        }

        if (changeView) {
            return `Update PIN`;
        }

        return `Assigning ${
            debitType === `givecard` ? `GiveCard` : `PEX Card`
        }`;
    };

    const renderView = () => {
        if (transferView) {
            return (
                <TransferView
                    memberBalance={memberBalance}
                    pexBalance={debitBalance}
                    nums={nums}
                />
            );
        }

        if (changeView) {
            return <PINView nums={nums} />;
        }

        return (
            <DebitView
                debitCardOptions={debitCardOptions}
                debitType={debitType}
                nums={nums}
                setDebitType={setDebitType}
            />
        );
    };

    const renderSubmitLabel = () => {
        if (error) {
            return `Error!`;
        }

        if (success) {
            return `Success!`;
        }

        if (transferView) {
            return `Transfer`;
        }

        if (changeView) {
            return `Update PIN`;
        }

        return `Assign Debit Card`;
    };

    useEffect(() => {
        if (!transferView) {
            if (nums.length < 4) {
                setDisabled(true);
            }
        } else {
            if (parseFloat(nums) > 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }

            // if amount is > member balance, sets amount to member balance
            if (parseFloat(nums) > memberBalance) {
                setNums(memberBalance.toString());
            }
        }
    }, [debitType, error, memberBalance, nums, transferView]);

    useEffect(() => {
        if (debitType && validateDebitNum(nums)) {
            setDisabled(false);
        }
    }, [debitType, nums, validateDebitNum]);

    useEffect(() => {
        setMessage(``);
        setError(false);
    }, [debitType]);

    return (
        <Card style={{ margin: 0 }}>
            <div className={classes.header}>
                {!assignView && !fromCatchUp ? (
                    <BackButton handleClick={() => handleDisplayForm()} />
                ) : null}
                <span style={{ marginLeft: '16px' }}>{renderHeader()}</span>
            </div>
            <div className={classes.dividingLine}></div>
            <div className={classes.assignBody}>{renderView()}</div>
            <div className={messageStyles()}>{message}</div>
            <div className={classes.cardBody}>
                <div className={classes.numPadWrapper}>
                    {inputLayout.map((row, i) => {
                        return (
                            <div key={i} className="row">
                                {row.split(' ').map((button, j) => {
                                    return (
                                        <IconButton
                                            key={j}
                                            className={`btn`}
                                            disabled={
                                                button === `.` && !transferView
                                                    ? true
                                                    : false
                                            }
                                            style={
                                                button === `.` && !transferView
                                                    ? { cursor: 'default' }
                                                    : { cursor: 'pointer' }
                                            }
                                            onClick={() => numberInput(button)}
                                            onKeyDown={(e) => keyInputs(e)}
                                            size="large"
                                        >
                                            {button === `<` ? (
                                                <i
                                                    className="far fa-backspace"
                                                    style={{ color: '#7378E8' }}
                                                />
                                            ) : button === `.` &&
                                              !transferView ? (
                                                ` `
                                            ) : (
                                                button
                                            )}
                                        </IconButton>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <CTAButton1
                        className={buttonStyles()}
                        disabled={disabled || loading}
                        onClick={() => handleSubmitForm(id)}
                    >
                        {!loading ? (
                            renderSubmitLabel()
                        ) : (
                            <LoadingCircle style={{ paddingTop: '10px' }} />
                        )}
                    </CTAButton1>
                </div>
            </div>
            {externalIDPopUp && (
                <UpdateModal
                    closeModalClick={() => handleExternalModalClick()}
                    title="External ID"
                >
                    <AddInfo
                        assignDebit={assignDebit}
                        externalID={externalID}
                        id={id}
                        nums={nums}
                        setExternalID={setExternalID}
                        type="external_id"
                    />
                </UpdateModal>
            )}
        </Card>
    );
}
